import React from 'react'
import { CardBoxProps } from './cardBox.props'
import { Box, Typography } from '@mui/material'
import style from "./CardBox.module.css"
import Button from '../button/Button'
import { ButtonType } from '../button/button.props'

function CardBox({ onClick, buttonText, children, image, title }: CardBoxProps) {
    return (
        <Box
            component="div"
            className={`${style["card-box"]}`}
        >
            <div>
                <Typography
                    variant='h4'
                    component="div"
                    fontSize="14px"
                    color="#4c5373"
                    fontWeight="500"
                >
                    {title}
                </Typography>

                <div className={`flex justify-center mt-4 mb-5 ${style.img}`}>
                    <img src={image} />
                </div>

                <div>
                    {children}
                </div>
            </div>


            <Button
                onClick={onClick}
                type={ButtonType.COLORED}
                className='mt-6'
            >
                {buttonText}
            </Button>
        </Box>
    )
}

export default CardBox