import React from 'react'
import TitleButton from '../title-and-button/TitleButton'
import { useAppDispatch } from '../../hooks/store.hook'
import { toggleCreatePaymentModel } from '../../store/slices/model.slice'
import { Box, Stack } from '@mui/material'
import PaymentMethod from '../payment-method/methods/PaymentMethod'
import PaymentList from '../payment-method/payment-list/PaymentList'

export default function BillingPaymentMethods() {

    const dispatch = useAppDispatch()


    return (
        <Box component="div">
            <TitleButton
                title='Payment Method'
                onClick={() => dispatch(toggleCreatePaymentModel())}
                buttonText=' + Add Card'
            />

            <PaymentList mt={4} />
        </Box>

    )
}
