import { Box } from '@mui/material'
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch } from '../../hooks/store.hook';
import Button from '../button/Button';
import { InputType } from '../input/input.props';
import Input from '../input/Input';
import { ButtonType } from '../button/button.props';
import { Link, useNavigate } from 'react-router-dom';
import RedirectLink from '../redirect-link/RedirectLink';

const schema = yup.object({
    email: yup.string().email().required("email is required"),
    invitation_code: yup.string().required("invite cosde is required"),
    password: yup.string().required("password is required"),
}).required();

export default function InviteLoginForm() {

    const dispatch = useAppDispatch()

    const navigate = useNavigate()

    //form part
    const { control, handleSubmit, setValue, formState: { errors, isDirty, isValid }, reset } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            email: "",
            password: "",
            invitation_code: ""
        }
    });

    const onSubmit = (data: any) => {
        reset(s => ({
            ...s,
            ...data
        }))
    }


    return (
        <Box
            component="div"
            width="340px"
            mx="auto"
        >
            <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                mb={2}
                mt={3}
            >

                <Input
                    control={control}
                    name="email"
                    error={errors.email?.message}
                    label="Email *"
                    buttonType='email'
                    type={InputType.WHITE}
                />

                <Input
                    control={control}
                    name="invitation_code"
                    error={errors.invitation_code?.message}
                    label="Invite Code *"
                    type={InputType.WHITE}
                />

                <Input
                    control={control}
                    name="password"
                    error={errors.password?.message}
                    label="Password *"
                    buttonType='password'
                    type={InputType.WHITE}
                />

                <Button
                    onClick={() => { return }}
                    type={isValid && isDirty ? ButtonType.COLORED : ButtonType.DISABLED}
                >
                    Get Started
                </Button>

            </Box>

        </Box >

    )
}
