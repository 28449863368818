import React from 'react'
import Button from '../button/Button'
import { CircularProgress } from '@mui/material'
import { SubmitButtonProps } from './submitButton.props'
import { ButtonType } from '../button/button.props'

export default function SubmitButton({
    isDirty, isValid, loading, text, className = "", width = "100%"
}: SubmitButtonProps) {
    return (
        <Button
            width={width}
            className={className}
            onClick={() => { return }}
            type={isValid && isDirty && !loading ? ButtonType.COLORED : ButtonType.DISABLED}
        >
            {loading ?
                <CircularProgress color="inherit" size={30} />
                :
                text
            }
        </Button>
    )
}
