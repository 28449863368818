import { Control } from "react-hook-form"

export type InputProps = {
    control: any,
    name: string,
    error: string,
    label: string,
    placeholder?: string,
    type?: InputType,
    buttonType?: string,
    width?: string,
    capitalize?: boolean
}

export enum InputType {
    DEFAULT = "default",
    WHITE = "white"
}