import React from 'react'
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { StripeWrapperProps } from './stripe-wrapper.props';

const stripePromise = loadStripe(process.env.REACT_APP_STRIP_PUBLISHABLE_KEY);

export default function StripeWrapper({ children }: StripeWrapperProps) {
    return (
        <Elements stripe={stripePromise} options={{
            appearance: { theme: "stripe" }
        }}>
            {children}
        </Elements>

    )
}
