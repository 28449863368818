import { Box, Typography, CircularProgress } from '@mui/material'
import { useAppDispatch } from '../../../hooks/store.hook'
import { toggleCreatePaymentModel, toggleDeletePaymentModel, toggleSelectPaymentModel } from '../../../store/slices/model.slice'
import { ButtonType } from '../../button/button.props'
import Button from '../../button/Button'
import PaymentList from '../payment-list/PaymentList'

export default function SelectCardModel() {

    const dispatch = useAppDispatch()

    return (
        <Box
            component="div"
            position="relative"
        >

            <Typography
                variant='h2'
                component="div"
                fontSize="32px"
                lineHeight="1.5"
                fontWeight="700"
                mb={2}
                align='center'
                color="#1f2f4c"
            >
                Select Card
            </Typography>

            <PaymentList withDelete={false} />

            <Box
                display="flex"
                gap="16px"
                mt={3}
            >
                <Button
                    onClick={() => dispatch(toggleSelectPaymentModel())}
                    width='40%'
                >
                    Close
                </Button>
                <Button
                    onClick={() => dispatch(toggleCreatePaymentModel())}
                    width={"59%"}
                >
                    + Add Card
                </Button>
            </Box>


        </Box >)
}
