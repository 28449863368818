import { Box } from '@mui/material'
import PageHeader from '../components/page-header/PageHeader'
import SettingForm from '../components/setting/setting-form/SettingForm'
import SettingImage from '../components/setting/setting-image/SettingImage'
import SubTitle from '../components/sub-title/SubTitle'
import useWindowSize from '../hooks/useWindowSize'

export default function Setting() {

    const { width } = useWindowSize()

    return (
        <Box
            component={"div"}
            width={{
                md: "auto", xs: `calc(${width}px - 107px)`,
            }}
        >
            <PageHeader
                title="Practice Settings"
                content="Make changes to your practice's name and logo."
                className='mb-6 md:mb-12'
            />

            <SubTitle title={"Your Practice"} />

            <SettingForm
                width='340px'
                buttonText={'Save Changes'}
                buttonWidth='200px'
            />

            <SubTitle title={"Your Logo"} className='mb-6 md:mt-14' />

            <SettingImage />
        </Box>
    )
}
