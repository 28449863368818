import { useAppSelector } from '../../hooks/store.hook';
import { Navigate } from 'react-router-dom';
import React from 'react';
import Models from '../../models/Models';


export default function PublicRoute({ Component, ...rest }: { Component: React.FC<any> }) {
    const { isLoggedIn } = useAppSelector(s => s.user)

    console.log("isLoggedIn = ", isLoggedIn)

    return (
        <>
            <Models />
            {!isLoggedIn ? <Component {...rest} /> : <Navigate to="/" replace={true} />}
        </>
    );

};

