import React, { useEffect } from 'react'
import CustomizedTables from '../../table/Table'
import { useAppDispatch, useAppSelector } from '../../../hooks/store.hook'
import moment from "moment";
import { Box } from '@mui/material';
import Pagination from '../../pagination/Pagination';
import { useSearchParams } from 'react-router-dom';
import { setOrderPage } from '../../../store/slices/order.slice';

export default function OrderList() {


    const [searchParams, setSearchParams] = useSearchParams();

    const page = parseInt(searchParams.get("page") || '1', 10);


    const {
        order: { orders, count, limit }
    } = useAppSelector(s => s)
    const dispatch = useAppDispatch()



    useEffect(() => {
        dispatch(setOrderPage(page))
    }, [page])

    if (orders.length === 0) return null

    const list = orders.map((item) => ({
        "id": item.id,
        "Order #": item?.order_number,
        "Date": item?.createdAt ? moment(item?.createdAt).format('M/DD/YY') : "",
        "Name": item?.imprint_name,
        "Address": item?.shipping_address,
        "Status": item?.status
    }))


    return (
        <Box
            component="div"
        >
            <CustomizedTables list={list} />

            <Pagination count={Math.ceil(count / limit) || 1} page={page} />

        </Box>
    )
}
