import { User } from './../../interfaces/user';
import { createSlice } from "@reduxjs/toolkit";
// import { login, logout, signup } from "./../thunk-actions/auth-actions";
// import { createUserProfile, deleteProfilePicture, getUser, updateUser, uploadProfilePicture } from "../thunk-actions/user-action";
import { apiErrorFormat } from "../../utilities/error-format";
import { login, signup } from '../thunk-actions/auth-actions';
import { getUser, updateUser } from '../thunk-actions/user-action';


const initialState: {
    user: User,
    isLoggedIn: boolean,
    loading: boolean,
    errors: string[],
    role: string
} = {
    isLoggedIn: false,
    loading: true,
    errors: [] as string[],
    role: "member",
    user: {
        id: 0,
        first_name: '',
        last_name: '',
        email: ''
    }
}

const slice = createSlice({
    name: "user",
    initialState,
    reducers: {
        logout(state) {
            localStorage.clear()
            state.isLoggedIn = false;
            state.user = initialState.user
        }
    },
    extraReducers: (builder) => {

        //login
        builder.addCase(login.fulfilled, (state, action) => {
            state.isLoggedIn = true
            state.user = action.payload.user;
        })

        //signup
        builder.addCase(signup.fulfilled, (state, action) => {
            state.isLoggedIn = true
            state.user = action.payload.user;
        })

        //getUser
        builder.addCase(getUser.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(getUser.fulfilled, (state, action) => {
            state.loading = false;
            state.user = action.payload;
            state.isLoggedIn = true
        })
        builder.addCase(getUser.rejected, (state, action) => {

            console.log("get user...")
            state.loading = false;
            state.isLoggedIn = false;
            localStorage.removeItem("token")
        })

        //updateUser
        builder.addCase(updateUser.pending, (state, action) => {
            state.errors = [];
        })
        builder.addCase(updateUser.fulfilled, (state, action) => {
            state.user = action.payload
        })
    }
})

export default slice.reducer;

const {
    logout
} = slice.actions

export { logout }