import { useAppSelector } from '../../hooks/store.hook';
import { Navigate } from 'react-router-dom';
import React from 'react';
import Models from '../../models/Models';


export default function PrivateRoute({ Component, ...rest }: { Component: React.FC<any> }) {
    const { isLoggedIn } = useAppSelector(s => s.user)

    return (
        <>
            {isLoggedIn ? <Component {...rest} /> : <Navigate to="/sign-in" replace={true} />}
        </>
    );

};

