import { Box, CircularProgress } from '@mui/material'
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { InputType } from '../input/input.props';
import Input from '../input/Input';
import { useAppDispatch } from '../../hooks/store.hook';
import { setSuccessMessage } from '../../store/slices/success.slice';
import { useState } from 'react';
import { setErrorMessages } from '../../store/slices/error.slice';
import { setErrorFormat } from '../../utilities/error-format';
import { sendResetPasswordEmail } from '../../utilities/api';
import SubmitButton from '../submit-button/SubmitButton';

const schema = yup.object({
    email: yup.string().email().required("email is required"),
}).required();

export default function SendRestPasswordLink() {

    const [loading, setLoading] = useState(false)

    const dispatch = useAppDispatch()

    //form part
    const { control, handleSubmit, formState: { errors, isDirty, isValid }, reset } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            email: "",
        }
    });

    const onSubmit = async (data: any) => {

        setLoading(true)
        try {
            await sendResetPasswordEmail(data)
            reset()
            dispatch(setSuccessMessage("Password reset link is sent successfully"))
        } catch (error) {
            dispatch(setErrorMessages(setErrorFormat(error)))
        } finally {
            setLoading(false)
        }
    }


    return (
        <Box
            component="div"
            width={{
                md: "340px", xs: "100%"
            }}
            mx="auto"
        >
            <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                mb={2}
                mt={3}
            >
                <Input
                    control={control}
                    name="email"
                    error={errors.email?.message}
                    label="Email *"
                    buttonType='email'
                    type={InputType.WHITE}
                />

                <SubmitButton
                    isValid={isValid}
                    isDirty={isDirty}
                    loading={loading}
                    text={'Submit'}
                />

            </Box>
        </Box >

    )
}
