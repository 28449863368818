import { Box } from '@mui/material'
import { ButtonType } from '../button/button.props'
import SubTitle from '../sub-title/SubTitle'
import TwoColumns from '../two-columns/TwoColumns'
import Button from '../button/Button'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../hooks/store.hook'
import moment from 'moment'

export default function BillingInfo() {

    const { plan: { plan }, credits: { count }, subscription: { subscription } } = useAppSelector(s => s)

    const navigate = useNavigate()

    return (
        <Box
            component="div"
        >

            <SubTitle title={" Your Plan Details"} />

            <Box
                component="div"
                sx={{
                    display: "flex",
                    flexDirection: {
                        sm: "row", xs: "column"
                    },
                    gap: { md: 0, xs: 1 },
                    justifyContent: "space-between",
                    my: 3
                }}
            >

                <div className='w-full md:w-5/12'>
                    <TwoColumns
                        leftText={'Plan'}
                        rightText={plan?.name ? plan.name : "----"}
                    />
                    <TwoColumns
                        leftText={'Monthly Price'}
                        rightText={plan?.isMonthly ? `$${plan.price_ber_brush * plan.quantity}` : "----"}
                    />
                    <TwoColumns
                        leftText={'Price per Brush'}
                        rightText={plan?.price_ber_brush ? `$${plan.price_ber_brush}` : "----"}
                    />
                </div>

                <div className='w-full md:w-5/12'>
                    <TwoColumns leftText={'Current Balance'} rightText={`${count} credits`} />
                    <TwoColumns
                        leftText={'Upcoming Credits'}
                        rightText={plan?.isMonthly ? `${plan.quantity} credits` : "----"}
                    />
                    <TwoColumns
                        leftText={'Next Billing Date'}
                        rightText={
                            plan?.isMonthly && subscription?.exp_date ?
                                `${moment(subscription.exp_date).format('MMMM D, YYYY')}` :
                                "----"
                        }
                    />
                </div>


            </Box>


            <Button
                onClick={() => { navigate("/plans") }}
                type={ButtonType.COLORED}
                width='200px'
            >
                Chose your plan
            </Button>
        </Box>
    )
}
