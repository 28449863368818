import React from 'react'
import { RedirectLinkProps } from './redirectLink.props'
import { Box } from '@mui/material'
import { Link } from 'react-router-dom'
import style from "./Redirect.module.css"

export default function RedirectLink({
    text,
    path,
    className = ""
}: RedirectLinkProps) {
    return (
        <Box
            component="div"
            className={className}
        >
            <Link className={style["redirect-link"]} to={path}>{text}</Link>
        </Box>)
}
