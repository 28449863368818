export interface PaymentMethod {

    id: number,

    card_number: string;

    brand: string;

    exp_month: string;

    exp_year: string;

    isDefault: boolean;

}

export const cardBrands: any = {
    amex: "/media/payment-brands/amex.png",
    diners: "/media/payment-brands/diners.png",
    discover: "/media/payment-brands/discover.png",
    eftpos_au: "/media/payment-brands/eftpos_au.png",
    jcb: "/media/payment-brands/jcb.png",
    mastercard: "/media/payment-brands/mastercard.png",
    unionpay: "/media/payment-brands/unionpay.png",
    visa: "/media/payment-brands/visa.png",
    unknown: ""
}