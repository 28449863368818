import { createSlice } from "@reduxjs/toolkit";
import { getUser } from "../thunk-actions/user-action";
import { login, signup } from "../thunk-actions/auth-actions";
import { logout } from "./user.slice";
import { UserPlan } from "../../interfaces/plan";
import { updatePlan } from "../thunk-actions/plan-action";


const initialState: {
    loading: boolean,
    plan: UserPlan
} = {
    loading: false,
    plan: null
}

const slice = createSlice({
    name: "plan",
    initialState,
    reducers: {
        updateUserPlan(state, action) {
            console.log("plan = ", action.payload.plan)
            state.plan = action.payload.plan

        }
    },

    extraReducers: (builder) => {
        //update plan name
        builder.addCase(updatePlan.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(updatePlan.fulfilled, (state, action) => {
            state.loading = false;
            state.plan = action.payload.plan
        })
        builder.addCase(updatePlan.rejected, (state, action) => {
            state.loading = false;
        })


        //update company with get user
        builder.addCase(getUser.fulfilled, (state, action) => {
            state.plan = action.payload.plan.length ? action.payload.plan[0] : null
        })

        //update plans with login
        builder.addCase(signup.fulfilled, (state, action) => {
            state.plan = action.payload.user.plan.length ? action.payload.user.plan[0] : null
        })

        //update plans with login
        builder.addCase(login.fulfilled, (state, action) => {
            state.plan = action.payload.user.plan.length ? action.payload.user.plan[0] : null
        })

        //update company with logout
        builder.addCase(logout, (state) => {
            state.plan = null
        })


    }
})

const { updateUserPlan } = slice.actions

export { updateUserPlan }

export default slice.reducer;