import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useAppSelector } from '../hooks/store.hook';

const styleContent = {

    height: "fit-content"


};

type props = {
    open: boolean,
    children?: React.ReactNode,
    toggleOpen: any,
    width?: any
}

const Model: React.FC<props> = ({ open = true, children, toggleOpen, width }) => {

    const { openOrderModel: { isOpen } } = useAppSelector(s => s.model)

    return (
        <Modal
            open={open}
            onClose={toggleOpen}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
                backgroundColor: "rgba(32, 40 ,80, 0.2)",
                "& > .MuiBackdrop-root": {
                    backgroundColor: "transparent"
                },
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                p: { md: 5, xs: 3 },
                // position: isOpen ? "fixed" : "absolute",
                bottom: "unset",
                minHeight: "100%",
                height: "100%"
                // overflowY: "scroll"
                // overflowY: scroll

            }}
        >

            <Box
                component="div"
                sx={{
                    overflowY: "auto",
                    maxHeight: "100%",
                    scrollbarWidth: 'none', // Hide scrollbar in Firefox
                    msOverflowStyle: 'none', // Hide scrollbar in IE/Edge
                    '&::-webkit-scrollbar': {
                        display: 'none' // Hide scrollbar in WebKit browsers (like Chrome and Safari)
                    },
                    borderRadius: "3px",
                    bgcolor: "#fff",
                    border: "none",
                    outline: "none",
                    boxShadow: 24,
                    p: {
                        md: "40px", xs: "20px"
                    },
                    mx: "auto",
                    width: {
                        xs: "100%",
                        md: width ? width : 420
                    },
                }}
            >
                {/* <Box sx={styleContent}> */}
                {children}
                {/* </Box> */}
            </Box>


        </Modal>
    );
}

export default Model