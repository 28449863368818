import React from 'react'
import PaginationWrap from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { PaginationProps } from './pagination.props';
import { Link, useSearchParams } from 'react-router-dom';
import { Box } from '@mui/material';

export default function Pagination({ count, page }: PaginationProps) {



    if (count === 1) return null
    return (
        <Box
            mt={4}
            display="flex"
            justifyContent="flex-end"
        >
            <PaginationWrap
                page={page}
                count={count}
                renderItem={(item) => (
                    <PaginationItem
                        sx={{
                            "&.Mui-selected": {
                                color: "#FFF",
                                backgroundColor: "#eb008b"
                            },
                            "&.Mui-selected:hover": {
                                backgroundColor: "rgba(235, 0, 139, 0.8)"
                            }
                        }}
                        component={Link}
                        to={item.page === 1 ? "" : `?page=${item.page}`}

                        // to={`/users${item.page === 1 ? '' : `?page=${item.page}`}`}
                        {...item}
                    />
                )}
            />
        </Box>

    )
}
