import { createAsyncThunk } from "@reduxjs/toolkit";
import { User } from "../../interfaces/user";
import * as api from "../../utilities/api"
import { Company } from "../../interfaces/company";



//update user
export const updateCompanyName = createAsyncThunk<
    Company,
    Partial<Company>,
    {
        rejectValue: unknown
    }
>("company/updateCompanyName", async (body, thunkApi) => {
    const { rejectWithValue } = thunkApi;
    try {
        const { data } = await api.updateCompanyName(body)
        return data.data;
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

//upload logo
export const uploadCompanyLogo = createAsyncThunk<
    string,
    any,
    {
        rejectValue?: unknown
    }
>("users/uploadCompanyLogo", async (file, thunkApi) => {
    const { rejectWithValue } = thunkApi;
    try {
        if (!file) return ""
        const formData = new FormData();
        formData.append("file", file)
        const { data } = await api.uploadCompanyLogo(formData)
        return data.data;
    } catch (error) {
        return rejectWithValue(error)
    }
})

// //delete image
// export const deleteProfilePicture = createAsyncThunk<
//     void,
//     void,
//     {
//         rejectValue?: unknown
//     }
// >("users/deleteImage", async (_, thunkApi) => {
//     const { rejectWithValue } = thunkApi;
//     try {

//         await api.deleteUserImage()
//         return;
//     } catch (error) {
//         return rejectWithValue(error)
//     }
// })
export function aut() { }