import { Box, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import Avatar from '@mui/material/Avatar';
import { useAppSelector } from '../../../hooks/store.hook';
import Button from '../../../components/button/Button';
import LogoutButton from '../../../components/logout-button/LogoutButton';
import Logo from '../../../components/logo/Logo';

export default function Nav() {

    const {
        user: { user: { first_name, last_name, email }, isLoggedIn },
        company: { company: { logo, name } }
    } = useAppSelector(s => s)
    return (
        <Box
            component="nav"
            sx={{
                backgroundColor: "#FFF",
                borderBottom: "1px solid rgb(218, 220, 227)",
                height: { sm: "80px", xs: isLoggedIn ? "auto" : "80px" },
                py: { sm: 0, xs: "10px" },
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                px: 3
            }}
        >


            <Logo />
            {isLoggedIn && <Box
                component="div"
                className='flex items-center justify-center'
                flexDirection={{
                    sm: "row", xs: "column"
                }}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    mb={{ sm: 0, xs: 1 }}
                >
                    <Box
                        component="div"
                        sx={{
                            border: "1px solid rgb(165, 169, 185)",
                            width: "32px",
                            height: "32px",
                            borderRadius: "50%",
                            mr: "10px"
                        }}
                        className='flex items-center justify-center'
                    >
                        <Avatar
                            sx={{
                                bgcolor: "#1F2F4C",
                                width: "26px",
                                height: "26px",
                                fontSize: "14px",
                                fontWeight: "bold"
                            }}
                            alt={first_name.toUpperCase()}
                            src="/broken-image.jpg"
                        />
                    </Box>

                    <Box
                        component="div"
                        className='flex flex-col justify-center'
                        mr={4}
                    >
                        <Typography
                            component="div"
                            fontSize="14px"
                            lineHeight="1.7"
                            color="rgb(31,47,76)"
                            fontWeight="500"
                            className='capitalize'
                        >
                            {first_name} {last_name}
                        </Typography>
                        <Typography
                            component="div"
                            fontSize="11px"
                            lineHeight="1"
                            color="rgb(31,47,76)"
                            fontWeight="500"
                        >
                            {name}
                        </Typography>
                    </Box>
                </Box>

                <Box
                    component="div"
                    width="124px"
                >
                    <LogoutButton />
                </Box>
            </Box>}

        </Box>
    )
}
