import { createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../../utilities/api"
import { PaymentMethod } from "../../interfaces/payment-method";
import { UserPlan } from "../../interfaces/plan";



//Add payment method 
export const updatePlan = createAsyncThunk<
    { plan: UserPlan },
    { planId: any },
    {
        rejectValue: unknown
    }
>("plan/updatePlan", async ({ planId }, thunkApi) => {
    const { rejectWithValue } = thunkApi;
    try {
        const { data } = await api.planCheckOut({ planId })
        return data.data;
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})