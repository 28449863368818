import { createSlice } from "@reduxjs/toolkit";
import { Company } from "../../interfaces/company";
import { updateCompanyName, uploadCompanyLogo } from "../thunk-actions/company-action";
import { getUser } from "../thunk-actions/user-action";
import { login } from "../thunk-actions/auth-actions";
import { logout } from "./user.slice";


const initialState: {
    loading: boolean,
    imageLoading: boolean,
    company: Company
} = {
    loading: false,
    imageLoading: false,
    company: {
        id: 0,
        name: "",
        logo: ""
    }
}

const slice = createSlice({
    name: "company",
    initialState,
    reducers: {},

    extraReducers: (builder) => {
        //update company name
        builder.addCase(updateCompanyName.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(updateCompanyName.fulfilled, (state, action) => {
            state.loading = false;
            state.company.name = action.payload.name
        })
        builder.addCase(updateCompanyName.rejected, (state, action) => {
            state.loading = false;
        })

        //update company logo
        builder.addCase(uploadCompanyLogo.pending, (state, action) => {
            state.imageLoading = true;
        })
        builder.addCase(uploadCompanyLogo.fulfilled, (state, action) => {
            state.imageLoading = false;
            state.company.logo = action.payload
        })
        builder.addCase(uploadCompanyLogo.rejected, (state, action) => {
            state.imageLoading = false;
        })


        //update company with get user
        builder.addCase(getUser.fulfilled, (state, action) => {
            state.company.id = action.payload.company?.id
            state.company.name = action.payload.company?.name;
            state.company.logo = action.payload.company?.logo?.url
        })

        //update company with login
        builder.addCase(login.fulfilled, (state, action) => {
            state.company.id = action.payload.user.company?.id
            state.company.name = action.payload.user.company?.name;
            state.company.logo = action.payload.user.company?.logo?.url

        })

        //update company with logout
        builder.addCase(logout, (state) => {
            state.company.id = 0
            state.company.name = "";
            state.company.logo = ""
        })


    }
})

export default slice.reducer;