import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from '../../../hooks/store.hook'
import { Box, Typography } from '@mui/material'
import Button from '../../button/Button'
import { toggleCreateOpenModel } from '../../../store/slices/model.slice'
import * as yup from "yup";
import Input from '../../input/Input';
import { createOrder, orderCheckOut } from '../../../utilities/api';
import { setErrorMessages } from '../../../store/slices/error.slice';
import { setErrorFormat } from '../../../utilities/error-format';
import SubmitButton from '../../submit-button/SubmitButton';
import { addOrder } from '../../../store/slices/order.slice';
import { subtractionCredit } from '../../../store/slices/credits.slice';
import { setSuccessMessage } from '../../../store/slices/success.slice';
import CardInfo from '../../payment-method/card-info/CardInfo';
import { useNavigate } from 'react-router-dom';
import { Checkout } from '../../../interfaces/payment.interface';
import moment from 'moment';


const schema = yup.object({
    imprinting_name: yup.string().required("imprinting name is required"),
    address_line_1: yup.string().optional(),
    address_line_2: yup.string().required("address line 2 is required"),
    city: yup.string().required("City is required"),
    state: yup.string().required("state is required"),
    phone: yup.string().optional(),
}).required();


export default function CreateOrderModel() {

    const [loading, setLoading] = useState(false)



    const { plan: { plan }, credits: { count }, subscription: { subscription } } = useAppSelector(s => s)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const price = plan
        ? plan.isMonthly
            ? (subscription?.status === "active" && moment(subscription.exp_date).isAfter(moment()))
                ? plan.default_price_ber_brush
                : "179$"
            : plan.default_price_ber_brush
        : "179$"; // Default price if the plan doesn't exist


    const price2 = plan
        ? plan.isMonthly
            ? (subscription?.status === "active" && moment(subscription.exp_date).isAfter(moment()))
                ? plan.default_price_ber_brush
                : "179 for expired subscription"
            : plan.default_price_ber_brush
        : "179"; // Default price if the plan doesn't exist


    //form part
    const { control, handleSubmit, formState: { errors, isDirty, isValid }, reset } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            imprinting_name: '',
            address_line_1: '',
            address_line_2: "",
            zip_code: "",
            city: "",
            state: "",
            phone: ""
        }
    });

    async function defaultCreate(payload: any) {
        try {
            const { data: order } = await createOrder(payload)
            dispatch(addOrder(order.data))
            dispatch(subtractionCredit())
            dispatch(setSuccessMessage("Order created"))
        } catch (error) {
            throw error
        }
    }

    async function createWithCredit(payload: any) {
        try {
            const { data } = await orderCheckOut(payload)

            const payment: Checkout = data.data

            if (payment.conformationUrl) {
                window.location.href = payment.conformationUrl
            } else {
                navigate(`/payments?payment_intent=${payment.paymentId}`)
            }
        } catch (error) {
            throw error
        }
    }

    const onSubmit = async (data: any) => {
        setLoading(true)
        try {

            const payload = {
                order: {
                    imprint_name: data.imprinting_name,
                    shipping_address: data?.address_line_1 || null,
                    phone: data?.phone || null,
                },
                address: {
                    address_Line_2: data.address_line_2,
                    zip_code: data.zip_code,
                    city: data.city,
                    state: data.state
                }
            }

            if (count > 0) {
                await defaultCreate(payload)
            } else {
                await createWithCredit(payload)
                dispatch(toggleCreateOpenModel())
            }
            reset()
        } catch (error) {
            dispatch(setErrorMessages(setErrorFormat(error)))
        } finally {
            setLoading(false)
        }
    }

    return (
        <Box
            component="div"
            height="fit-content"
        >

            <Typography
                variant='h2'
                component="div"
                color="#1f2f4c"
                fontSize={{ md: "24px", xs: "18px" }}
                lineHeight="1.5"
                fontWeight="700"
                mb={2}
            >
                Create a New Brush Order
            </Typography>


            <Typography
                component="div"
                color="#1f2f4c"
                fontSize={{ md: "16px", xs: "12px" }}
                lineHeight="1.2"
                mb={4}
            >
                Enter the patient's and shipping address. We'll imprint their name and your logo on the brush and mail it directly to them.
            </Typography>

            <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
            // mb={4}
            >

                <Typography
                    variant='h4'
                    component="div"
                    color="#1f2f4c"
                    fontSize="14px"
                    lineHeight={1.4}
                    mb={2}
                    fontWeight="700"
                >
                    Step 1: Setup Imprinting
                </Typography>

                <Input
                    control={control}
                    name="imprinting_name"
                    error={errors.imprinting_name?.message}
                    label="Imprinting Name *"
                    placeholder='Ex. "Mallory Stevens"'
                />


                <Box
                    component="div"
                >

                    <Typography
                        variant='h4'
                        component="div"
                        color="#1f2f4c"
                        fontSize="14px"
                        lineHeight={1.4}
                        mb={2}
                        fontWeight="700"
                    >
                        Step 2: Setup Shipping
                    </Typography>

                    <Input
                        control={control}
                        name="address_line_2"
                        error={errors.address_line_2?.message}
                        label="Address Line 1 *"
                    />

                    < Input
                        control={control}
                        name="phone"
                        error={errors.phone?.message}
                        label="Phone"
                    />

                    <Box
                        display="flex"
                        gap={2}
                    >
                        <Input
                            control={control}
                            name="address_line_1"
                            error={errors.address_line_1?.message}
                            label="Address Line 2"
                        />
                        <Input
                            control={control}
                            name="zip_code"
                            error={errors.zip_code?.message}
                            label="Zip Code *"
                        />

                    </Box>

                    <Box
                        display="flex"
                        gap={2}
                    >
                        <Input
                            control={control}
                            name="city"
                            error={errors.city?.message}
                            label="City *"
                        />

                        <Input
                            control={control}
                            name="state"
                            error={errors.state?.message}
                            label="State *"
                        />
                    </Box>
                </Box>


                <Box
                    component="div"
                    mb={5}
                >

                    <Typography
                        variant='h4'
                        component="div"
                        color="#1f2f4c"
                        fontSize="14px"
                        lineHeight={1.4}
                        mb={2}
                        fontWeight="700"
                    >
                        Step 3: Manage Payment
                    </Typography>

                    {count ?
                        <Typography
                            component="div"
                            color="#1f2f4c"
                            fontSize="14px"
                            lineHeight="1.7"
                            mb={4}
                        >

                            You're on the <span className='font-bold'>{plan?.name || "---"}</span>. You have <span className='font-bold'>{count} credit(s)</span>.

                        </Typography>
                        :
                        <>
                            <Typography
                                component="div"
                                color="#1f2f4c"
                                fontSize="14px"
                                lineHeight="1.7"
                                mb={4}
                            >

                                {plan ?
                                    <>
                                        You're on the  <span className='font-bold'>{plan?.name}</span>. You currently have 0 credits, but you can still order brushes <span className='font-bold'>${price2}</span>.
                                    </>
                                    :
                                    "You don't have a default plan set, but you can still order brushes $179."
                                }


                            </Typography>

                            <CardInfo width='100%' />

                        </>
                    }

                </Box>

                <Box
                    display="flex"
                    gap="16px"
                    flexDirection={{
                        xs: "column", md: "row"
                    }}
                >
                    <Button
                        width="33%"
                        onClick={() => dispatch(toggleCreateOpenModel())}
                    >
                        Cancel
                    </Button>

                    <SubmitButton
                        isValid={isValid}
                        isDirty={isDirty}
                        loading={loading}
                        text={
                            count > 0 ?
                                'Order with 1 Credit' :
                                `Order with $${price}`
                        }
                        width='220px'
                    />

                </Box>

            </Box>
        </Box >
    )
}
