import { Box } from '@mui/material'
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from '../../hooks/store.hook';
import { InputType } from '../input/input.props';
import Input from '../input/Input';
import { useNavigate } from 'react-router-dom';
import RedirectLink from '../redirect-link/RedirectLink';
import { signup } from '../../store/thunk-actions/auth-actions';
import SubmitButton from '../submit-button/SubmitButton';

const schema = yup.object({
    first_name: yup.string().required("first name is required"),
    last_name: yup.string().required("last name is required"),
    email: yup.string().email().required("email is required"),
    password: yup.string().min(8).max(16).required("password is required"),
}).required();

export default function SignupForm() {


    const navigate = useNavigate()
    const { loading } = useAppSelector(s => s.auth)
    const dispatch = useAppDispatch()

    //form part
    const { control, handleSubmit, setValue, formState: { errors, isDirty, isValid }, reset } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            first_name: "",
            last_name: "",
            email: "",
            password: ""
        }
    });

    const onSubmit = (data: any) => {
        dispatch(signup({ credentials: data }))
            .unwrap().then(() => {
                reset(s => ({
                    ...s,
                    ...data
                }))
                navigate("/practice-name")
            })
    }


    return (
        <Box
            component="div"
            width={{
                md: "340px", xs: "100%"
            }}
            mx="auto"
        >
            <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                mb={2}
                mt={3}
            >

                <Box
                    component="div"
                    display="flex"
                    justifyContent="space-between"
                    gap={2}
                >
                    <Input
                        control={control}
                        name="first_name"
                        error={errors.first_name?.message}
                        label="First Name *"
                        type={InputType.WHITE}
                    />

                    <Input
                        control={control}
                        name="last_name"
                        error={errors.last_name?.message}
                        label="Last Name *"
                        type={InputType.WHITE}
                    />
                </Box>

                <Input
                    control={control}
                    name="email"
                    error={errors.email?.message}
                    label="Email *"
                    buttonType='email'
                    type={InputType.WHITE}
                />


                <Input
                    control={control}
                    name="password"
                    error={errors.password?.message}
                    label="Password *"
                    buttonType='password'
                    type={InputType.WHITE}
                />

                <SubmitButton
                    isValid={isValid}
                    isDirty={isDirty}
                    loading={loading}
                    text={'Get Stared'}
                />


            </Box>

            <RedirectLink
                text='Already have an account?'
                path='/sign-in'
            />

        </Box >

    )
}
