import { Box } from '@mui/material'
import React from 'react'
import PageHeader from '../components/page-header/PageHeader'
import BillingInfo from '../components/billing/BillingInfo'
import BillingPaymentMethods from '../components/billing/BillingPaymentMethods'
import useWindowSize from '../hooks/useWindowSize'

export default function Billing() {

    const { width } = useWindowSize()


    return (
        <Box
            component={"div"}
            width={{
                md: "auto", xs: `calc(${width}px - 107px)`,
            }}
        >
            <PageHeader
                title="Billing"
                content='Manage your plan and payment methods. '
                className='mb-6 md:mb-12'
            />

            <BillingInfo />

            <BillingPaymentMethods />


        </Box>

    )
}
