import { Box, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../hooks/store.hook';
import PageHeader from '../components/page-header/PageHeader';
import { ButtonType } from '../components/button/button.props';
import Button from '../components/button/Button';
import CustomizedTables from '../components/table/Table';
import { toggleCreateOpenModel } from '../store/slices/model.slice';
import OrderList from '../components/order/order-list/OrderList';


const Order = () => {

    const dispatch = useAppDispatch()

    return (
        <Box
            component={"div"}
        >
            <PageHeader
                title="Orders"
                content='Create and view brush orders for patients.'
                className='mb-6'
            />

            <div className='flex md:justify-end justify-center'>
                <Button
                    type={ButtonType.COLORED}
                    onClick={() => dispatch(toggleCreateOpenModel())}
                    width='200px'
                >
                    + Create New Order
                </Button>
            </div>

            <div className='mt-6 md:mt-12'>
                <OrderList />
            </div>
        </Box>

    )
}

export default Order