import { Box, Typography, Divider } from '@mui/material'
import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/store.hook'
import SubTitle from '../../sub-title/SubTitle';
import { SettingImageProps } from './settimgImageProps';
import { uploadCompanyLogo } from '../../../store/thunk-actions/company-action';
import { setSuccessMessage } from '../../../store/slices/success.slice';
import Loader from '../../loader/Loader';

export default function SettingImage({ className = "", width = "100%" }: SettingImageProps) {
    const { company: { logo }, imageLoading } = useAppSelector(s => s.company)
    const [imagePreview, setImagePreview] = useState(null);
    const [file, setFile] = useState(null)

    const dispatch = useAppDispatch();

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files[0];
        if (file) {
            dispatch(uploadCompanyLogo(file))
                .unwrap()
                .then(() => {
                    setFile(file)
                    dispatch(setSuccessMessage("Logo updated"))

                    // const reader = new FileReader();
                    // reader.onloadend = () => {
                    //     // setImagePreview(reader.result);
                    //     // dispatch(changeAppLogo(reader.result)); // Assuming you have an action named setLogoAction to update the logo state

                    // };
                })
        }
    };

    return (
        <Box
            component="div"
            alignItems="center"
            flexDirection="column"
            className={`${className}`}
            width={{ md: width, xs: "100%" }}

        >

            <label htmlFor="logo-upload">
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: { sm: "340px", xs: "100%" },
                        height: "340px",
                        p: 5,
                        border: "1px solid rgb(195, 200, 217)",
                        borderRadius: "10px",
                        backgroundColor: "#fff",
                        mt: 3,
                        cursor: 'pointer',
                        position: "relative",
                        overflow: "hidden"
                    }}
                    component="div"
                >

                    {imageLoading && <Loader withOverlay={true} />}

                    {!logo && !imagePreview ?
                        <Box>
                            + Click to upload logo
                        </Box>
                        :
                        <Box
                            component="div"
                            width="100%"
                        >
                            <img
                                className='w-full'
                                src={file ? URL.createObjectURL(file) : logo}
                            />
                        </Box>
                    }
                    <input
                        id="logo-upload"
                        type="file"
                        accept="image/*"
                        className='hidden'
                        onChange={handleImageChange}
                    />
                </Box>
            </label>

            <Typography
                component="div"
                mt={1}
                width={{ sm: "340px", xs: "100%" }}
                color="rgb(121, 126, 150)"
                fontSize="12px"
                lineHeight="1.2"
            >

                Please note: we use a laser engraver to imprint your logo on the brushes which will result in a black and white final image.

            </Typography>
        </Box >
    )
}
