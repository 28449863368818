import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/store.hook'
import { Box, Typography } from '@mui/material'
import Button from '../../button/Button'
import { toggleOpenOrderModel } from '../../../store/slices/model.slice'
import { ButtonType } from '../../button/button.props'
import { setErrorMessages } from '../../../store/slices/error.slice'
import { setErrorFormat } from '../../../utilities/error-format'
import { getUserOrder } from '../../../utilities/api'
import Loader from '../../loader/Loader'
import { Order } from '../../../interfaces/order.interface'
import moment from "moment";

export default function OpenOrderModel() {


    const { orderId } = useAppSelector(s => s.model.openOrderModel)
    const dispatch = useAppDispatch()

    const [order, setOrder] = useState<Order>(null)
    const [loading, setLoading] = useState(true)


    const orderData: any = {
        "Order #": order?.id ? `#${order?.order_number}` : "----",
        "Date Created": order?.createdAt ? moment(order.createdAt).format('M/DD/YY') : "----",
        "Imprinting Name": order?.imprint_name ? order.imprint_name : "----",
        "Shipping Provider": order?.shipping?.shipping_provider ?
            order.shipping?.shipping_provider : "----",
        "Tracking #": order?.shipping?.tracking_number ?
            order.shipping?.tracking_number : "----",
        "Shipping Address": order?.shipping_address ? order.shipping_address : "----",

    }

    async function getOrderDetails() {
        setLoading(true);
        try {
            const { data } = await getUserOrder(orderId);
            setOrder(data.data)
        } catch (error) {
            setOrder(null)
            dispatch(setErrorMessages(setErrorFormat(error)))
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getOrderDetails()
    }, [orderId]);

    if (loading) return <div>Loading...</div>
    return (
        <Box
            component="div"
            position="relative"
        >

            {/* {loading && <Loader />} */}

            <Typography
                variant='h2'
                component="div"
                color="#1f2f4c"
                fontSize={{ md: "24px", xs: "18px" }}
                lineHeight="1.5"
                fontWeight="700"
                mb={2}
            >
                {order ? `Order #${order?.id || 0} - ${order.imprint_name}` : "----"}
            </Typography>

            <Box
                component="div"
                display="flex"
                alignItems="center"
                mb={4}
            >

                <Box
                    component="div"
                    sx={{
                        width: "8px",
                        height: "8px",
                        backgroundColor: "#061aa0",
                        borderRadius: "50%",
                        mr: "10px"
                    }}
                ></Box>

                <Typography
                    component="span"
                    color="#1f2f4c"
                    fontSize="14px"
                    lineHeight="1.7"
                >
                    {order?.status || "----"}
                </Typography>

            </Box>

            <Box
                mb={5}
            >

                {Object.keys(orderData).map((key, index) => <Box
                    display={"flex"}
                    key={index}
                >
                    <Typography
                        component="div"
                        color="#797e96"
                        fontSize="14px"
                        lineHeight="1.7"
                        className='w-5/12'
                    >
                        {key}
                    </Typography>
                    <Typography
                        className='w-7/12'
                        component="div"
                        color="#1f2f4c"
                        fontSize="14px"
                        lineHeight="1.7"
                        ml={5}
                    >
                        {orderData[key]}
                    </Typography>
                </Box>
                )}



            </Box>

            <Box
                display="flex"
                gap="16px"
                flexDirection={{
                    xs: "column", md: "row"
                }}
            >
                <Button
                    onClick={() => dispatch(toggleOpenOrderModel(0))}
                    width='33%'
                >
                    Close
                </Button>
                <Button
                    onClick={() => { return }}
                    type={order?.shipping?.tracking_url ? ButtonType.COLORED : ButtonType.DISABLED}
                    width='66%'
                >
                    {order?.shipping?.tracking_url ?
                        <a href={order.shipping.tracking_url}>
                            Track Order
                        </a>
                        :
                        "Track Order"
                    }
                </Button>
            </Box>

        </Box >
    )
}
