import { Box } from '@mui/material'
import React from 'react'
import PageHeader from '../components/page-header/PageHeader'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Button from '../components/button/Button'
import { ButtonType } from '../components/button/button.props'

export default function NodFoundPage() {

    const navigate = useNavigate();

    return (
        <Box
            component="div"
            maxWidth="600px"
            mx="auto"
            pt="80px"
        >


            <PageHeader
                title="Welcome aboard Flight 404. In a few moments, we'll be departing the world as you know it..."
                className='text-center mb-8'
            />

            <Button
                type={ButtonType.COLORED}
                onClick={() => navigate("/")}
            >
                Regain Awareness
            </Button>
        </Box>
    )
}
