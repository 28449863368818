import { Box } from '@mui/material'
import React from 'react'
import PageHeader from '../components/page-header/PageHeader'
import SignupForm from '../components/signup-form/SignupForm'

export default function Signup() {
    return (
        <Box
            component="div"
            maxWidth={{
                md: "600px", xs: "100%"
            }}
            mx="auto"
            pt="80px"
        >

            <PageHeader
                title='Get started with Zana. It takes just a couple minutes.'
                className='text-center mb-8'
            />

            <SignupForm />

        </Box>
    )
}
