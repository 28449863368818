import React from 'react'
import style from "./Loader.module.css"
import { Box, CircularProgress } from '@mui/material'
import { LoaderProps } from './loader.props'

export default function Loader({ withOverlay = false }: LoaderProps) {
    return (
        <Box
            component="div"
            sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: withOverlay ? "rgba(0 , 0 , 0 , 0.6)" : "#FFF"
            }}
        >
            <CircularProgress
                color="inherit"
                size={60}
                sx={{
                    '.MuiCircularProgress-circle': {
                        stroke: '#eb008b',
                    }
                }}

            />

        </Box>
    )
}
