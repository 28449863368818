import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableProps } from './table.props';
import { useAppDispatch } from '../../hooks/store.hook';
import { toggleOpenOrderModel } from '../../store/slices/model.slice';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#f7f8fa",
        color: "#1f2f4c",
        fontSize: 14
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: "20px 16px",
        color: "#1f2f4c",
        cursor: "pointer"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#fff",
    },
    '&:nth-of-type(even)': {
        backgroundColor: "#F1F2F5",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function CustomizedTables({ list }: TableProps) {

    const dispatch = useAppDispatch();

    return (
        <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        {Object.keys(list[0]).map((key, index) =>
                            <StyledTableCell sx={{
                                minWidth: key === "Order #" ? "90px" : "0"
                            }} key={index}>{key}</StyledTableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list.map((row, index) => (
                        <StyledTableRow
                            key={row.id}
                            onClick={() => dispatch(toggleOpenOrderModel(row["id"]))}
                        >
                            {Object.keys(list[0]).map((key, index) =>
                                <StyledTableCell
                                    sx={{
                                        minWidth: key === "Order #" ? "90px" : "0"
                                    }}
                                    key={index}
                                >{row[key]}</StyledTableCell>
                            )}
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}