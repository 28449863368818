import { ReactNode } from "react"

export type buttonProps = {
    onClick: () => void,
    className?: string,
    children: ReactNode
    type?: ButtonType,
    width?: string
}
export enum ButtonType {
    OUTLINE = "outline",
    COLORED = "colored",
    DISABLED = "disabled"
}