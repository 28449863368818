// api/github.api.ts
import { useQuery } from '@tanstack/react-query';
import { getUserOrders } from '../utilities/api';
import { setErrorFormat } from '../utilities/error-format';
import { Query } from '../interfaces/query.interface';

export const getOrders = async (query: Query, enabled: boolean) => {
    if (!enabled) return false

    try {
        const { data } = await getUserOrders(query);
        return data.data;
    } catch (error) {
        throw setErrorFormat(error);
    }
};

export const useGetOrders = (query: Query, enabled: boolean) => {
    // console.log("enabled = ", enabled)

    return useQuery(['getOrders', query, enabled], () => getOrders(query, enabled), {});
};
