import { Box } from '@mui/material'
import React from 'react'
import PageHeader from '../components/page-header/PageHeader'
import SignupForm from '../components/signup-form/SignupForm'
import SigInForm from '../components/sign-in-form/SignInForm'

export default function SignIn() {
    return (
        <Box
            component="div"
            maxWidth={{
                md: "600px", xs: "100%"
            }}
            mx="auto"
            pt="80px"
        >

            <PageHeader
                title='Sign in to Zana.'
                className='text-center mb-8'
            />

            <SigInForm />

        </Box>
    )
}
