import { Box } from '@mui/material'
import * as yup from "yup";
import Input from '../../input/Input';
import { useAppSelector, useAppDispatch } from '../../../hooks/store.hook';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { InputType } from '../../input/input.props';
import SubTitle from '../../sub-title/SubTitle';
import { updateUser } from '../../../store/thunk-actions/user-action';
import { setSuccessMessage } from '../../../store/slices/success.slice';
import SubmitButton from '../../submit-button/SubmitButton';
import { useState } from 'react';

const schema = yup.object({
    first_name: yup.string().required("first name is required"),
    last_name: yup.string().required("last name is required"),
    email: yup.string().email().required("email is required"),
}).required();

export default function AccountForm() {

    const { user: { first_name, last_name, email } } = useAppSelector(s => s.user);

    const [loading, setLoading] = useState(false)

    const dispatch = useAppDispatch()

    //form part
    const { control, handleSubmit, setValue, formState: { errors, isDirty, isValid }, reset } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            first_name,
            last_name,
            email
        }
    });

    const onSubmit = async (data: any) => {
        setLoading(true)
        dispatch(updateUser(data)).unwrap().then(() => {
            reset(s => ({
                ...s,
                ...data
            }))
            dispatch(setSuccessMessage("user updated successfully"))
        }).finally(() => setLoading(false))
    }


    return (
        <Box
            component="div"
        >
            <SubTitle title='Profile' />

            <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                mb={4}
                mt={3}
            >

                <Box
                    component="div"
                    display="flex"
                    justifyContent="space-between"
                    width={{ sm: "340px", xs: "100%" }}
                    gap={2}
                >
                    <Input
                        control={control}
                        name="first_name"
                        error={errors.first_name?.message}
                        label="First Name *"
                        type={InputType.WHITE}
                        capitalize={true}
                    />

                    <Input
                        control={control}
                        name="last_name"
                        error={errors.last_name?.message}
                        label="Last Name *"
                        type={InputType.WHITE}
                        capitalize={true}
                    />
                </Box>

                <Box
                    component="div"
                    display="flex"
                    justifyContent="space-between"
                    width={{ sm: "340px", xs: "100%" }}
                >
                    <Input
                        control={control}
                        name="email"
                        error={errors.email?.message}
                        label="Email *"
                        buttonType='email'
                    />
                </Box>


                <SubmitButton
                    isValid={isValid}
                    isDirty={isDirty}
                    loading={loading}
                    text={'Save Changes'}
                    width='340px'
                />

            </Box>
        </Box>

    )
}
