import { createSlice } from "@reduxjs/toolkit";
import { apiErrorFormat, setErrorFormat } from '../../utilities/error-format';
import { login, signup } from "../thunk-actions/auth-actions";
// import { createUserProfile, uploadProfilePicture } from './../thunk-actions/user-action';



const initialState = {
    loading: false,
    errors: [] as string[],
}

const slice = createSlice({
    name: "auth",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        //login
        builder.addCase(login.pending, (state) => {
            state.loading = true;
            state.errors = []
        });

        builder.addCase(login.fulfilled, (state) => {
            state.loading = false;
        });

        builder.addCase(login.rejected, (state, action) => {
            state.loading = false;
            state.errors = apiErrorFormat(action.payload);
        })

        //signup
        builder.addCase(signup.pending, (state, action) => {
            state.loading = true;
            state.errors = []
        });

        builder.addCase(signup.fulfilled, (state, action) => {
            state.loading = false;
        });

        builder.addCase(signup.rejected, (state, action) => {
            state.loading = false;
            state.errors = setErrorFormat(action.payload);
        })

        // //logout
        // builder.addCase(logout.pending, (state, action) => {
        //     state.loading = true;
        //     state.errors = []
        // });

        // builder.addCase(logout.fulfilled, (state, action) => {
        //     state.loading = false;
        //     state.step = 1
        // });

        // builder.addCase(logout.rejected, (state, action) => {
        //     state.loading = false;
        //     state.errors = apiErrorFormat(action.payload);
        // })
    }
})

export default slice.reducer;