import React, { useEffect, useState } from 'react'
import { useNavigate, useNavigation, useParams } from 'react-router-dom'
import { IServerPlan } from '../interfaces/plan'
import { plans } from '../data/plans'
import { Box, CircularProgress, Typography } from '@mui/material'
import PageHeader from '../components/page-header/PageHeader'
import Button from '../components/button/Button'
import { ButtonType } from '../components/button/button.props'
import { useAppDispatch, useAppSelector } from '../hooks/store.hook'
import CardInfo from '../components/payment-method/card-info/CardInfo'
import { toggleCheckoutPlanModel } from '../store/slices/model.slice'
import { getPlan } from '../utilities/api'

export default function CustomPlan() {

    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    const { id } = useParams()

    const { paymentMethod: { paymentMethods }, plan: { loading } } = useAppSelector(s => s)
    const { isLoggedIn } = useAppSelector(s => s.user)

    const defaultCard = paymentMethods.find(method => method.isDefault)

    const [plan, setPlan] = useState<IServerPlan>(null)

    async function getAppPlan() {
        try {
            const { data } = await getPlan(+id)

            setPlan(data.data);
        } catch (error) {
            navigate("/404")
        }
    }

    useEffect(() => {
        getAppPlan()
    }, [])

    return (
        <Box
            component="div"
            maxWidth="480px"
            mx="auto"
        >
            <PageHeader
                title='Setup your billing.'
                className='text-center mb-12'
            />
            {plan && <>
                <Box
                    component="div"
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mb: 2
                    }}
                >
                    <Typography
                        component="div"
                        color="#1f2f4c"
                        fontSize="24px"
                        lineHeight="1.5"
                        fontWeight="500"
                    >
                        {plan.name}
                    </Typography>
                    <Typography
                        component="div"
                        color="#1f2f4c"
                        fontSize="24px"
                        lineHeight="1.5"
                        fontWeight="500"
                    >
                        ${plan.price_ber_brush * plan.quantity} {plan.isMonthly ? `/ ${plan.interval_count > 1 ? plan.interval_count : ""} ${plan.interval_unit}` : "each"}
                    </Typography>
                </Box>

                <Box
                    component="div"
                    mb={4}
                >
                    <div
                        className='flex justify-between'
                    >
                        <Typography
                            component="div"
                            color="#1f2f4c"
                            fontSize="16px"
                            lineHeight="1.5"
                        >
                            {plan.isMonthly ? "Brush per subscription" : " Brush per plan"}
                        </Typography>
                        <Typography
                            component="div"
                            color="#1f2f4c"
                            fontSize="16px"
                            lineHeight="1.5"
                        >
                            {plan.quantity}
                        </Typography>
                    </div>

                    <div
                        className='flex justify-between'
                    >
                        <Typography
                            component="div"
                            color="#1f2f4c"
                            fontSize="16px"
                            lineHeight="1.5"
                        >
                            Price per brush
                        </Typography>
                        <Typography
                            component="div"
                            color="#1f2f4c"
                            fontSize="16px"
                            lineHeight="1.5"
                        >
                            ${plan.price_ber_brush}
                        </Typography>
                    </div>
                    <div
                        className='flex justify-between'
                    >
                        <Typography
                            component="div"
                            color="#1f2f4c"
                            fontSize="16px"
                            lineHeight="1.5"
                        >
                            Additional brush price
                        </Typography>
                        <Typography
                            component="div"
                            color="#1f2f4c"
                            fontSize="16px"
                            lineHeight="1.5"
                        >
                            ${plan.default_price_ber_brush}
                        </Typography>
                    </div>
                </Box>

                {isLoggedIn && <Box
                    component="div"
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: {
                            sm: "center",
                            xs: "flex-start"
                        },
                        mb: 4,
                        flexDirection: {
                            sm: "row", xs: "column"
                        }
                    }}
                >
                    <Typography
                        component="div"
                        color="#1f2f4c"
                        fontSize="24px"
                        lineHeight="1.5"
                        fontWeight="500"
                    >
                        Card Info
                    </Typography>

                    <CardInfo />

                </Box>}

                {plan.description && <Typography
                    component="div"
                    color="#1f2f4c"
                    fontSize="14px"
                    lineHeight="1.7"
                    textAlign="center"
                >
                    {plan.description}
                </Typography>}


                {isLoggedIn ?
                    <Button
                        onClick={() => dispatch(toggleCheckoutPlanModel({
                            planId: plan.id,
                            cost: `$${plan.price_ber_brush * plan.quantity}`
                        }))}
                        type={!defaultCard ? ButtonType.DISABLED : ButtonType.COLORED}
                        width='340px'
                        className='mx-auto mt-6'
                    >
                        Confirm ${plan.price_ber_brush * plan.quantity}
                    </Button>
                    :
                    <Button
                        onClick={() => navigate("/sign-in")}
                        width='340px'
                        className='mx-auto mt-6'
                    >
                        Login
                    </Button>
                }
            </>}
        </Box>
    )
}
