import { PaymentMethod } from './../../interfaces/payment-method';
import { createSlice } from "@reduxjs/toolkit";
import { getUser } from "../thunk-actions/user-action";
import { logout } from "./user.slice";
import { addPaymentMethod, deletePaymentMethod, setDefaultPaymentMethod } from '../thunk-actions/payment-method-action';
import { login } from '../thunk-actions/auth-actions';


const initialState: {
    loading: boolean,
    paymentMethods: PaymentMethod[]
} = {
    loading: false,
    paymentMethods: []
}

const slice = createSlice({
    name: "paymentMethod",
    initialState,
    reducers: {},

    extraReducers: (builder) => {
        //add payment methods
        builder.addCase(addPaymentMethod.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(addPaymentMethod.fulfilled, (state, action) => {
            const methods = state.paymentMethods.map(method => ({ ...method, isDefault: false }))
            state.loading = false;
            state.paymentMethods = [action.payload, ...methods]
        })
        builder.addCase(addPaymentMethod.rejected, (state, action) => {
            state.loading = false;
        })


        //delete payment methods
        builder.addCase(deletePaymentMethod.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(deletePaymentMethod.fulfilled, (state, action) => {
            state.loading = false;
            state.paymentMethods = state.paymentMethods.filter(method => method.id !== action.payload)
        })
        builder.addCase(deletePaymentMethod.rejected, (state, action) => {
            state.loading = false;
        })

        //set default payment methods
        builder.addCase(setDefaultPaymentMethod.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(setDefaultPaymentMethod.fulfilled, (state, action) => {
            state.loading = false;
            state.paymentMethods = state.paymentMethods.map((method) => ({
                ...method,
                isDefault: method.id === action.payload ? true : false
            }))
        })
        builder.addCase(setDefaultPaymentMethod.rejected, (state, action) => {
            state.loading = false;
        })


        //update with get user
        builder.addCase(getUser.fulfilled, (state, action) => {
            state.paymentMethods = action.payload.paymentMethods
        })

        //update with login
        builder.addCase(login.fulfilled, (state, action) => {
            state.paymentMethods = action.payload.user.paymentMethods
        })

        //update logout
        builder.addCase(logout, (state) => {
            state.paymentMethods = []
        })


    }
})

export default slice.reducer;