import { number } from 'yup';
import { createSlice } from "@reduxjs/toolkit";
import { Plan } from '../../interfaces/plan';


const initialState = {
    isOpen: false,
    openOrderModel: {
        isOpen: false,
        orderId: 0
    },
    createOrderModel: {
        isOpen: false,
    },
    createPaymentModel: {
        isOpen: false
    },
    deletePaymentModel: {
        id: 0,
        isOpen: false
    },
    selectPaymentModel: {
        isOpen: false
    },
    checkoutPlanModel: {
        plan: null as Plan,
        isOpen: false
    },

}

const slice = createSlice({
    name: "models",
    initialState,
    reducers: {
        toggleOpenOrderModel: (state, action) => {
            state.isOpen = !state.openOrderModel.isOpen;
            state.openOrderModel.isOpen = !state.openOrderModel.isOpen;
            state.openOrderModel.orderId = action.payload
        },
        toggleCreateOpenModel: (state) => {
            state.isOpen = !state.createOrderModel.isOpen;
            state.createOrderModel.isOpen = !state.createOrderModel.isOpen;
        },

        toggleCreatePaymentModel: (state) => {
            state.isOpen = !state.createPaymentModel.isOpen;
            state.createPaymentModel.isOpen = !state.createPaymentModel.isOpen;
        },

        toggleDeletePaymentModel: (state, action) => {
            state.isOpen = !state.deletePaymentModel.isOpen;
            state.deletePaymentModel.isOpen = !state.deletePaymentModel.isOpen;
            state.deletePaymentModel.id = action.payload
        },

        toggleSelectPaymentModel: (state) => {
            state.isOpen = !state.selectPaymentModel.isOpen;
            state.selectPaymentModel.isOpen = !state.selectPaymentModel.isOpen;
        },

        toggleCheckoutPlanModel: (state, action) => {
            state.isOpen = !state.checkoutPlanModel.isOpen;
            state.checkoutPlanModel.isOpen = !state.checkoutPlanModel.isOpen;
            state.checkoutPlanModel.plan = action.payload
        },
    },
})

export default slice.reducer;

const {
    toggleOpenOrderModel,
    toggleCreateOpenModel,
    toggleCreatePaymentModel,
    toggleDeletePaymentModel,
    toggleSelectPaymentModel,
    toggleCheckoutPlanModel
} = slice.actions;

export {
    toggleOpenOrderModel,
    toggleCreateOpenModel,
    toggleCreatePaymentModel,
    toggleDeletePaymentModel,
    toggleSelectPaymentModel,
    toggleCheckoutPlanModel
}