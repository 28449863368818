import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../hooks/store.hook'
import {
    toggleCreateOpenModel,
    toggleCreatePaymentModel,
    toggleOpenOrderModel,
    toggleDeletePaymentModel,
    toggleSelectPaymentModel
} from '../store/slices/model.slice'
import Model from "./Model"
import OpenOrderModel from '../components/order/open-order-model/OpenOrderModel'
import CreateOrderModel from '../components/order/create-order-model/CreateOrderModel'
import AddPaymentForm from '../components/payment-method/add-payment/AddPaymentForm'
import StripeWrapper from '../components/stripe/StripeWrapper'
import DeleteMethod from '../components/payment-method/delete-method/DeleteMethod'
import SelectCardModel from '../components/payment-method/select-card/SelectCardModel'
import PlanCheckout from '../components/plan/plan-checkout/PlanCheckout'


const Models = () => {
    const { model } = useAppSelector(s => s)
    const dispatch = useAppDispatch();


    return (
        <>
            <Model
                open={model.openOrderModel.isOpen}
                toggleOpen={() => dispatch(toggleOpenOrderModel(0))}
            >
                <OpenOrderModel />
            </Model>


            <Model
                open={model.createOrderModel.isOpen}
                toggleOpen={() => dispatch(toggleCreateOpenModel())}
            >
                <CreateOrderModel />
            </Model>


            <Model
                open={model.createPaymentModel.isOpen}
                toggleOpen={() => dispatch(toggleCreatePaymentModel())}
            >
                <StripeWrapper>
                    <AddPaymentForm />
                </StripeWrapper>
            </Model>

            <Model
                open={model.deletePaymentModel.isOpen}
                toggleOpen={() => dispatch(toggleDeletePaymentModel(0))}
            >
                <DeleteMethod />
            </Model>

            <Model
                open={model.selectPaymentModel.isOpen}
                toggleOpen={() => dispatch(toggleSelectPaymentModel())}
                width="auto"
            >
                <SelectCardModel />
            </Model>

            <Model
                open={model.checkoutPlanModel.isOpen}
                toggleOpen={() => dispatch(toggleDeletePaymentModel(0))}
            >
                <PlanCheckout />
            </Model>


        </>
    )
}

export default Models