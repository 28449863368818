import React from 'react'
import Nav from './nav/Nav'
import Container from './container/Container'
import { Outlet } from 'react-router-dom'
import { Box } from '@mui/material'
import { useAppSelector } from '../../hooks/store.hook'
import Models from '../../models/Models'

export default function SecondeLayout() {

    const { model: { isOpen } } = useAppSelector(s => s)

    return (
        <>
            <Models />
            <Box
                component="div"
                sx={{
                    filter: isOpen ? "blur(5.44px)" : "none"
                }}
            >
                <Nav />
                <Container>
                    <Outlet />
                </Container>
            </Box>
        </>
    )
}
