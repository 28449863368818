import { Stack } from '@mui/material'
import React from 'react'
import { useAppSelector } from '../../../hooks/store.hook'
import PaymentMethod from '../methods/PaymentMethod'

export default function PaymentList({ mt = 0, withDelete = true }: { mt?: number, withDelete?: boolean }) {
    const { paymentMethods } = useAppSelector(s => s.paymentMethod)

    const methods = [...paymentMethods]
    return (
        <Stack mt={{ md: mt, xs: 3 }} spacing={2}>
            {
                methods
                    .sort((a, b) => (a.isDefault === b.isDefault) ? 0 : a.isDefault ? -1 : 1)
                    .map(method => <PaymentMethod key={method.id} method={method} withDelete={withDelete} />)
            }
        </Stack>
    )
}
