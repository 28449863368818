import React from 'react'
import { PlaneCardBoxProps } from './planeCarBox.props'
import { Box, Typography } from '@mui/material'
import style from "./PlanCardBox.module.css"
import Button from '../button/Button'
import { useNavigate } from 'react-router-dom'

function PlaneCardBox({ path, buttonType, title, content, price }: PlaneCardBoxProps) {

    const navigate = useNavigate()

    return (
        <Box
            component="div"
            className={`${style["card-box"]}`}
        >
            <div>
                <Typography
                    variant='h4'
                    component="div"
                    fontSize="24px"
                    color="rgb(31,47,76)"
                    fontWeight="500"
                    textAlign="center"
                >
                    {title}
                </Typography>

                <div className='mt-8'>
                    <Typography
                        component="div"
                        color="#1f2f4c"
                        fontSize="14px"
                        lineHeight="1.7"
                    >
                        {price}
                    </Typography>
                </div>

            </div>
            <div>

                <Button
                    onClick={() => navigate(path)}
                    type={buttonType}
                    className='mt-4'
                >
                    Select
                </Button>

                <Typography
                    component="div"
                    color="#1f2f4c"
                    fontSize="14px"
                    lineHeight="1.7"
                    mt={2}
                >
                    {content}
                </Typography>

            </div>
        </Box>
    )
}

export default PlaneCardBox