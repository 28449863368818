import { Box, Typography } from '@mui/material'
import PageHeader from '../components/page-header/PageHeader'
import SettingImage from '../components/setting/setting-image/SettingImage'
import Button from '../components/button/Button'
import { ButtonType } from '../components/button/button.props'
import { useNavigate } from 'react-router-dom'
import RedirectLink from '../components/redirect-link/RedirectLink'

export default function LogoUpload() {

    const navigate = useNavigate();
    return (
        <Box
            component="div"
            maxWidth="600px"
            mx="auto"
            pt="80px"
        >

            <PageHeader
                title="Upload your logo for your customized brushes."
                className='text-center mb-4'
            />

            <Box
                component="div"
                width="340px"
                mx="auto"
            >
                <SettingImage />
                <Button
                    className='mt-8'
                    onClick={() => navigate("/")}
                    type={ButtonType.COLORED}
                >
                    Next
                </Button>

                <RedirectLink
                    text='Skip this step for now'
                    path='/'
                    className='mt-6'
                />
            </Box>
        </Box>
    )
}
