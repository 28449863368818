import { createSlice } from "@reduxjs/toolkit";
import { Order } from "../../interfaces/order.interface";


const initialState: {
    orders: Order[],
    count: number,
    activeCount: number,
    page: number,
    limit: number
} = {
    orders: [],
    count: 0,
    activeCount: 0,
    page: 1,
    limit: 10
}

const slice = createSlice({
    name: "orders",
    initialState,
    reducers: {
        getOrders(state, action) {
            state.orders = action.payload.rows;
            state.count = action.payload.count
            state.activeCount = action.payload.activeCount
        },

        addOrder(state, action) {
            state.orders = [action.payload, ...state.orders];
            state.count += 1
            state.activeCount += 1
        },

        setOrderPage(state, action) {
            state.page = action.payload
        },

        restOrders(state) {
            state = initialState
        }

    },
})

export default slice.reducer;

const {
    getOrders,
    restOrders,
    setOrderPage,
    addOrder
} = slice.actions;

export {
    getOrders,
    restOrders,
    setOrderPage,
    addOrder
}