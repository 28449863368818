import { Box, CircularProgress } from '@mui/material'
import * as yup from "yup";
import Input from '../../input/Input';
import { useAppSelector, useAppDispatch } from '../../../hooks/store.hook';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { ButtonType } from '../../button/button.props';
import Button from '../../button/Button';
import { InputType } from '../../input/input.props';
import { SettingFormProps } from './settingForm.props';
import { updateCompanyName } from '../../../store/thunk-actions/company-action';
import { setSuccessMessage } from '../../../store/slices/success.slice';
import { useNavigate } from 'react-router-dom';
import SubmitButton from '../../submit-button/SubmitButton';

const schema = yup.object({
    name: yup.string().required("name is required"),
}).required();

export default function SettingForm({
    className = "",
    width = "100%",
    buttonWidth = "100%",
    buttonText
}: SettingFormProps) {

    const { company: { name }, loading } = useAppSelector(s => s.company);

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    //form part
    const { control, handleSubmit, setValue, formState: { errors, isDirty, isValid }, reset } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            name: name,
        }
    });

    const onSubmit = (data: any) => {
        dispatch(updateCompanyName(data))
            .unwrap().then(() => {
                reset(s => ({
                    ...s,
                    ...data
                }));
                dispatch(setSuccessMessage("Name changed successfully"))
                if (buttonText === "Next")
                    navigate("/upload-logo")
            })
    }


    return (
        <Box
            component="div"
            className={`${className}`}
            width={{ md: width, xs: "100%" }}
        >
            <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                mb={4}
                mt={3}
            >

                <Input
                    control={control}
                    name="name"
                    error={errors.name?.message}
                    label="Practice Name *"
                    placeholder='Ex. "Zana Dental App"'
                    type={InputType.WHITE}
                />

                <SubmitButton
                    isValid={isValid}
                    isDirty={isDirty}
                    loading={loading}
                    text={buttonText}
                    width={buttonWidth}
                />

            </Box>
        </Box>

    )
}
