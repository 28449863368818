import { createSlice } from "@reduxjs/toolkit";
import { login, signup } from "../thunk-actions/auth-actions";
import { setErrorFormat } from "../../utilities/error-format";
import { getUser, updateUser } from "../thunk-actions/user-action";
import { updateCompanyName, uploadCompanyLogo } from "../thunk-actions/company-action";
import { addPaymentMethod, deletePaymentMethod } from "../thunk-actions/payment-method-action";
import { updatePlan } from "../thunk-actions/plan-action";


const initialState: { messages: string[], isOpen: boolean } = {
    isOpen: false,
    messages: []
}

const slice = createSlice({
    name: "error",
    initialState,
    reducers: {
        setErrorMessages(state, action) {
            state.isOpen = true;
            state.messages = action.payload
        },
        clearErrorMessages(state) {
            state.isOpen = false;
            state.messages = []
        },
    },
    extraReducers: (builder) => {

        //sign in errors
        builder.addCase(login.pending, (state, action) => {
            state.isOpen = false;
            state.messages = []
        })
        builder.addCase(login.rejected, (state, action) => {
            state.isOpen = true;
            state.messages = setErrorFormat(action.payload)
        })

        //sign up errors
        builder.addCase(signup.pending, (state, action) => {
            state.isOpen = false;
            state.messages = []
        })
        builder.addCase(signup.rejected, (state, action) => {
            state.isOpen = true;
            state.messages = setErrorFormat(action.payload)
        })

        //get user errors
        builder.addCase(updateUser.pending, (state, action) => {
            state.isOpen = false;
            state.messages = []
        })
        builder.addCase(updateUser.rejected, (state, action) => {
            state.isOpen = true;
            state.messages = setErrorFormat(action.payload)
        })

        //update company name  errors
        builder.addCase(updateCompanyName.pending, (state, action) => {
            state.isOpen = false;
            state.messages = []
        })
        builder.addCase(updateCompanyName.rejected, (state, action) => {
            state.isOpen = true;
            state.messages = setErrorFormat(action.payload)
        })

        //update company logo  errors
        builder.addCase(uploadCompanyLogo.pending, (state, action) => {
            state.isOpen = false;
            state.messages = []
        })
        builder.addCase(uploadCompanyLogo.rejected, (state, action) => {
            state.isOpen = true;
            state.messages = setErrorFormat(action.payload)
        })

        //add payment method errors
        builder.addCase(addPaymentMethod.pending, (state, action) => {
            state.isOpen = false;
            state.messages = []
        })
        builder.addCase(addPaymentMethod.rejected, (state, action) => {
            state.isOpen = true;
            state.messages = setErrorFormat(action.payload)
        })

        //delete payment method errors
        builder.addCase(deletePaymentMethod.pending, (state, action) => {
            state.isOpen = false;
            state.messages = []
        })
        builder.addCase(deletePaymentMethod.rejected, (state, action) => {
            state.isOpen = true;
            state.messages = setErrorFormat(action.payload)
        })

        //update plan errors
        builder.addCase(updatePlan.pending, (state, action) => {
            state.isOpen = false;
            state.messages = []
        })
        builder.addCase(updatePlan.rejected, (state, action) => {
            state.isOpen = true;
            state.messages = setErrorFormat(action.payload)
        })
    }
})

export default slice.reducer;

const {
    setErrorMessages,
    clearErrorMessages
} = slice.actions;

export {
    setErrorMessages,
    clearErrorMessages
}