import { createSlice } from "@reduxjs/toolkit";
import { getUser } from "../thunk-actions/user-action";
import { login, signup } from "../thunk-actions/auth-actions";
import { logout } from "./user.slice";
import { updatePlan } from "../thunk-actions/plan-action";
import { Subscription } from "../../interfaces/subscription.interface";
import moment from "moment";

const initialState: {
    subscription: Subscription
} = {
    subscription: null
}

const slice = createSlice({
    name: "plan",
    initialState,
    reducers: {
        updateUserSubscription(state) {

            state.subscription = {
                ...state.subscription,
                exp_date: moment().add(1, "month").toString()
            }

        }
    },

    extraReducers: (builder) => {

        //update company with get user
        builder.addCase(getUser.fulfilled, (state, action) => {
            state.subscription = action.payload.subscription
        })

        //update plans with login
        builder.addCase(login.fulfilled, (state, action) => {
            state.subscription = action.payload.user.subscription
        })

        //update company with logout
        builder.addCase(logout, (state) => {
            state.subscription = null
        })


    }
})

const { updateUserSubscription } = slice.actions

export { updateUserSubscription }

export default slice.reducer;