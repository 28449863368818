import { ListItem } from "../components/nav-list/props"
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

export const topNav: ListItem[] = [
    {
        path: "/",
        key: "home",
        text: "Home",
        Icon: HomeOutlinedIcon,
    }
]

export const middleNav: ListItem[] = [
    {
        path: "/orders",
        key: "orders",
        text: "Orders",
        Icon: ListAltOutlinedIcon,
    },
    {
        path: "/billing",
        key: "billing",
        text: "Billing",
        Icon: AttachMoneyOutlinedIcon,
    },
    {
        path: "/settings",
        key: "settings",
        text: "Practice Settings",
        Icon: SettingsOutlinedIcon,
    }
]

export const bottomNav: ListItem[] = [
    {
        path: "/account",
        key: "account",
        text: "My Account",
        Icon: AssignmentIndOutlinedIcon,
    }
]