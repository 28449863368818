import React from 'react'
import { TwoColumnsProps } from './twoColumns.props'
import { Typography } from '@mui/material'

export default function TwoColumns({ leftText, rightText }: TwoColumnsProps) {
    return (
        <div className='flex md:gap-0 gap-2' >
            <Typography
                fontSize={{ md: "14px", xs: "12px" }}
                fontWeight="700"
                lineHeight="1.7"
                color="rgb(121, 126, 150)"
                className='w-6/12'
            >
                {leftText}
            </Typography>
            <Typography
                fontSize={{ md: "14px", xs: "12px" }}
                fontWeight="700"
                lineHeight="1.7"
                color="#1f2f4c"
                className='w-6/12'
            >
                {rightText}
            </Typography>
        </div>
    )
}
