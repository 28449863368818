import { createBrowserRouter } from "react-router-dom";
import Home from "../routes/Home";
import Root from "../routes/Root";
import Order from "../routes/Order";
import Billing from "../routes/Billing";
import Setting from "../routes/Setting";
import Account from "../routes/Account";
import Pricing from "../routes/Pricing";
import Plan from "../routes/Plan";
import Signup from "../routes/Signup";
import SignIn from "../routes/SignIn";
import RestPassword from "../routes/ResetPassword";
import NodFoundPage from "../routes/404";
import LogoUpload from "../routes/LogoUpload";
import PracticeName from "../routes/PracticeName";
import InviteLogin from "../routes/InviteLogin";
import FirstLayout from "../layouts/layout-1/FirstLayout";
import PrivateRoute from "../components/private-route/PrivateRoute";
import SecondeLayout from "../layouts/layout-2/SecondeLayout";
import PublicRoute from "../components/public-route/PublicRoute";
import Payments from "../routes/Payments";
import CustomPlan from "../routes/CustomPlan";

const routes = createBrowserRouter([
    {
        path: "/",
        element: <FirstLayout />,
        children: [
            {
                index: true,
                element: <PrivateRoute Component={Home} />
            },
            {
                path: "orders",
                element: <PrivateRoute Component={Order} />
            },
            {
                path: "billing",
                element: <PrivateRoute Component={Billing} />
            },
            {
                path: "settings",
                element: <PrivateRoute Component={Setting} />
            },
            {
                path: "account",
                element: <PrivateRoute Component={Account} />
            },
        ]
    },

    {
        path: "/",
        element: <SecondeLayout />,
        children: [
            {
                path: "signup",
                element: <PublicRoute Component={Signup} />
            },
            {
                path: "sign-in",
                element: <SignIn />
            },
            {
                path: "invite-login",
                element: <PublicRoute Component={InviteLogin} />

            },
            {
                path: "reset-password",
                element: <RestPassword />
            },

            {
                path: "upload-logo",
                element: <PrivateRoute Component={LogoUpload} />
            },
            {
                path: "practice-name",
                element: <PrivateRoute Component={PracticeName} />
            },
            {
                path: "payments",
                element: <PrivateRoute Component={Payments} />
            },
            {
                path: "plans",
                children: [
                    {
                        index: true,
                        element: <PrivateRoute Component={Pricing} />
                    },
                    {
                        path: "app/:id",
                        element: <CustomPlan />
                    },
                    {
                        path: ":id",
                        element: <PrivateRoute Component={Plan} />

                    }
                ],
            },
            {
                path: "*",
                element: <NodFoundPage />,
            },
        ]
    },

    // {
    //     path: "*",
    //     element: <NodFoundPage />,
    //     // children: [
    //     //     {
    //     //         element: <NodFoundPage />
    //     //     }
    //     // ]
    // }
])

export default routes