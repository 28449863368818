import moment from "moment";
import { Plan } from "../interfaces/plan";

export const plans: Plan[] = [
    {
        id: "service-&-save",
        planId: 2,
        name: "Subscribe & Save",
        cost: "$327 / mo",
        details: [
            {
                title: "Monthly brushes",
                data: "3"
            },
            {
                title: "Price per brush",
                data: "$109"
            },
            {
                title: "Additional brush price",
                data: "$129"
            },
            {
                title: "Credit rollover",
                data: "Yes"
            },
            {
                title: "Credits valid for",
                data: "12 months"
            },
            {
                title: "Start date",
                data: moment().format("M/DD/YY")

            }
        ],
        note: <>
            With our <span className="font-bold">Subscribe & Save</span> plan, you get a discount by buying 3 order credits every month. Credits always roll over, and you can buy more at your discounted price if you run out.
        </>
    },

    {
        id: "one-time",
        name: "One at a Time",
        cost: "$179 each",
        planId: 1,
        details: [
            {
                title: "Due today",
                data: "$0"
            },
            {
                title: "Start date",
                data: moment().format("M/DD/YY")
            }
        ],
        note: <>
            With our <span className="font-bold">One at a Time</span> plan, you’ll only be charged when you place an order. Nothing’s due today and we’ll confirm your billing each time you place a new order.
        </>
    },

    {
        id: "bulk-buy",
        name: "Bulk Buy",
        cost: "$3096 Once",
        planId: 3,
        details: [
            {
                title: "Quantity",
                data: "24"
            },
            {
                title: "Price per brush",
                data: "$129"
            },
            {
                title: "Credits valid for",
                data: "12 months"
            },
        ],
        note: <>
            With our <span className="font-bold">Bulk Buy</span> plan, you can buy a bundle of brush credits at our best volume discounts.
        </>
    }
]