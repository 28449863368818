import React from 'react'
import { PaymentInfoProps } from './payment-info.props'
import { Box, Grid, Typography } from '@mui/material'
import moment from 'moment'

export default function PaymentInfo({ payment }: PaymentInfoProps) {



    const paymentData: any = {
        "Payment #": payment?.id,
        "Charge #": payment?.chargeId,
        "status:": payment?.status,
        price: `$${payment?.price}`,
        description: payment?.description,
        "Card Num": `********${payment?.card_num}`,
        Date: moment(payment?.createdAt).format("MM/DD/YYYY")

    }



    return (
        <Box
            mt={5}
            component="div"
            sx={{
                py: 3,
                px: 4,
                backgroundColor: "#fff",
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                borderRadius: "8px",
                maxWidth: "500px",
                mx: "auto"
            }}
        >
            <Typography
                variant='h2'
                component="div"
                color="#1f2f4c"
                fontSize="24px"
                lineHeight="1.5"
                fontWeight="700"
                align="center"
                mb={4}
                width="100%"
            >
                Payment Details
            </Typography>


            <Box
                component="div"
            >

                {Object.keys(paymentData).map((key, index) => <Grid key={index} container >
                    <Grid item xs={4} mt={2}>
                        <Typography
                            component="div"
                            color="#1f2f4c"
                            fontSize="18px"
                            lineHeight="1.7"
                            fontWeight="700"
                        >
                            {key}
                        </Typography>
                    </Grid>
                    <Grid item xs={8} mt={2}>
                        <Typography
                            component="div"
                            color="#797e96"
                            fontSize="18px"
                            lineHeight="1.7"
                            width="100%"
                            align="right"
                        >
                            {paymentData[key]}
                        </Typography>
                    </Grid>
                </Grid>)}



            </Box>
        </Box>
    )
}
