import { configureStore } from '@reduxjs/toolkit';
import user from "./slices/user.slice";
import auth from "./slices/auth.slice";
import company from "./slices/company.slice";
import model from "./slices/model.slice"
import success from "./slices/success.slice"
import errors from "./slices/error.slice"
import order from "./slices/order.slice"
import paymentMethod from "./slices/paymentMethod"
import plan from "./slices/plan.slice"
import credits from "./slices/credits.slice"
import subscription from "./slices/subscription.slice"

// ...

const store = configureStore({
    reducer: {
        auth,
        user,
        company,
        model,
        success,
        errors,
        order,
        paymentMethod,
        plan,
        credits,
        subscription
    },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;