import React from 'react'
import PageHeader from '../components/page-header/PageHeader'
import { Box, Button, Divider, Typography } from '@mui/material'
import AccountForm from '../components/account/account-form/AccountForm'
import TitleButton from '../components/title-and-button/TitleButton'
import { useNavigate } from 'react-router-dom'

export default function Account() {

    const navigate = useNavigate()

    return (
        <Box
            component={"div"}
        >
            <PageHeader
                title="My Account"
                content="Manage your account details."
                className='mb-6 md:mb-12'
            />

            <AccountForm />

            <TitleButton
                title='Security'
                onClick={() => navigate("/reset-password")}
                buttonText='Reset Password'
                mt='55px'
            />
        </Box>
    )
}
