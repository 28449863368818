import React, { useState } from 'react'
import { PaymentMethodProps } from './payment-method.props'
import { Box, CircularProgress, IconButton, Typography } from '@mui/material'
import { cardBrands } from '../../../interfaces/payment-method'
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment'
import { useAppDispatch } from '../../../hooks/store.hook';
import { toggleDeletePaymentModel, toggleSelectPaymentModel } from '../../../store/slices/model.slice';
import { setErrorMessages } from '../../../store/slices/error.slice';
import { setErrorFormat } from '../../../utilities/error-format';
import { setDefaultPaymentMethod } from '../../../store/thunk-actions/payment-method-action';

export default function PaymentMethod({ method, setting = true, withDelete = true }: PaymentMethodProps) {


    const [loading, setLoading] = useState(false)
    const dispatch = useAppDispatch()

    async function setDefaultCard() {
        setLoading(true)
        try {
            dispatch(setDefaultPaymentMethod(method.id)).unwrap().then(() => {
                if (!withDelete) {
                    dispatch(toggleSelectPaymentModel())
                }
            }).finally(() => setLoading(false))
        } catch (error) {
            setLoading(false)

            dispatch(setErrorMessages(setErrorFormat(error)))
        }
    }
    return (
        <Box
            component="div"
            sx={{
                p: setting ? 2 : 1,
                backgroundColor: "#fff",
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                borderRadius: "8px",
                display: "flex",
                flexDirection: { sm: "row", xs: "column" },
                alignItems: "center",
                justifyContent: "space-between",
                gap: { xs: 2, md: 5 },
                position: "relative"
            }}
        >
            {loading && <Box
                sx={{
                    position: "absolute",
                    inset: 0,
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "rgba(0 , 0 , 0 , 0.5)",
                    zIndex: "999"
                }}
            >
                <CircularProgress color="error" size={40} />
            </Box>}
            <Box
                component="div"
                display="flex"
                flexDirection={{ sm: "row", xs: "column" }}
                alignItems="center"
                gap={2}
            >
                <Box
                    component="div"
                    sx={{
                        maxWidth: setting ? "85px" : "70px",
                        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                        borderRadius: "8px",
                        overflow: "hidden"
                    }}
                >
                    <img src={cardBrands[method.brand]} />
                </Box>
                <Box
                    component="div"
                >
                    <Typography
                        component="div"
                        fontSize="14px"
                        fontWeight="700"
                        lineHeight="1.7"
                        color="#1f2f4c"
                    >
                        {<span className='capitalize'>{method.brand}</span>} ending with {method.card_number}
                    </Typography>
                    <Typography
                        component="div"
                        fontSize="12px"
                        lineHeight="1.7"
                        color="rgb(121, 126, 150)"
                    >
                        Exp. date {method.exp_month}/{moment(method.exp_year).format("YY")}
                    </Typography>
                </Box>
            </Box>

            {setting && <Box
                component="div"
                display="flex"
                alignItems="center"
                gap={2}
            >
                {method.isDefault ?
                    <Typography
                        component="div"
                        sx={{
                            fontSize: "12px",
                            color: "#FFF",
                            py: 1,
                            px: 2,
                            backgroundColor: "#eb008b",
                            borderRadius: 1
                        }}
                    >
                        Default
                    </Typography>
                    :
                    <Typography
                        component="div"
                        sx={{
                            fontSize: "12px",
                            color: "#eb008b",
                            cursor: "pointer",
                        }}
                        onClick={setDefaultCard}
                    >
                        Set as Default
                    </Typography>

                }

                {withDelete && <IconButton
                    aria-label="delete"
                    size="large"
                    onClick={() => dispatch(toggleDeletePaymentModel(method.id))}
                >
                    <DeleteIcon color="error" />
                </IconButton>}


            </Box>}
        </Box>
    )
}
