import React, { useState } from 'react'
import Button from '../../button/Button'
import { Box, Typography, CircularProgress } from '@mui/material'
import { toggleCheckoutPlanModel, toggleDeletePaymentModel } from '../../../store/slices/model.slice'
import { ButtonType } from '../../button/button.props'
import { useAppDispatch, useAppSelector } from '../../../hooks/store.hook'
import { setErrorMessages } from '../../../store/slices/error.slice'
import { setSuccessMessage } from '../../../store/slices/success.slice'
import { updatePlan } from '../../../store/thunk-actions/plan-action'
import { setErrorFormat } from '../../../utilities/error-format'
import { useNavigate } from 'react-router-dom'
import { planCheckOut } from '../../../utilities/api'
import { Checkout } from '../../../interfaces/payment.interface'

export default function PlanCheckout() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch();
    const { model: { checkoutPlanModel: { plan } } } = useAppSelector(s => s)

    const [loading, setLoading] = useState(false)

    async function onclick() {
        setLoading(true)
        try {

            const { data } = await planCheckOut({ planId: plan.planId })

            const payment: Checkout = data.data

            dispatch(toggleCheckoutPlanModel(null))

            if (payment.conformationUrl) {
                window.location.href = payment.conformationUrl
            } else {
                navigate(`/payments?payment_intent=${payment.paymentId}`)
            }

        } catch (error) {
            dispatch(setErrorMessages(setErrorFormat(error)))
        } finally {
            setLoading(false)
        }
    }


    return (
        <Box
            component="div"
            position="relative"
        >

            <Typography
                variant='h2'
                component="div"
                color="#d32f2f"
                fontSize="32px"
                lineHeight="1.5"
                fontWeight="700"
                mb={2}
                align='center'
            >
                Warning
            </Typography>

            <Typography
                variant='h2'
                component="div"
                color="#1f2f4c"
                fontSize="18px"
                lineHeight="1.5"
                mb={3}
                align='center'
            >
                Are you sure you want to pay {<span className='font-bold'>{plan.cost}</span>} for {<span className='font-bold'>{plan.name}</span>} plan?
            </Typography>

            <Box
                display="flex"
                gap="16px"
            >
                <Button
                    onClick={() => dispatch(toggleCheckoutPlanModel(null))}
                    width='33%'
                >
                    Close
                </Button>
                <Button
                    onClick={onclick}
                    type={!loading ? ButtonType.COLORED : ButtonType.DISABLED}
                    width='66%'
                >
                    {loading ? <CircularProgress color="inherit" size={30} /> : `Confirm ${plan.cost}`}
                </Button>
            </Box>

        </Box >
    )
}
