import { Box } from '@mui/material'
import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/store.hook'
import PaymentMethod from '../methods/PaymentMethod'
import Button from '../../button/Button'
import { toggleCreatePaymentModel, toggleSelectPaymentModel } from '../../../store/slices/model.slice'
import { mobileBreakPoint } from '../../../utilities/constant'

export default function CardInfo({ width = "200px" }: { width?: string }) {

    const dispatch = useAppDispatch()
    const { paymentMethods } = useAppSelector(s => s.paymentMethod)

    const defaultCard = paymentMethods.find(method => method.isDefault)
    return (
        <Box
            component="div"
            sx={{
                width: { sm: "auto", xs: "100%" },
                mt: { sm: "0", xs: 2 }
            }}
        >

            {
                paymentMethods.length ?
                    !defaultCard ?
                        <Button
                            onClick={() => dispatch(toggleSelectPaymentModel())}
                            width="200px"
                        >
                            Select Card
                        </Button>
                        :
                        <Box
                            onClick={() => dispatch(toggleSelectPaymentModel())}
                            component="div"
                            sx={{ cursor: "pointer" }}
                        >
                            <PaymentMethod method={defaultCard} setting={false} withDelete={false} />
                        </Box>
                    :
                    <Button
                        onClick={() => dispatch(toggleCreatePaymentModel())}
                        width={width}
                    >
                        + Add Card
                    </Button>
            }

        </Box>
    )
}
