import { Box } from '@mui/material'
import React from 'react'
import { TitleButtonProps } from './titleButton.props'
import Button from '../button/Button'
import SubTitle from '../sub-title/SubTitle'

export default function TitleButton({ title, buttonText, onClick, mt = "64px" }: TitleButtonProps) {
    return (
        <Box
            component="div"
            mt={{ md: mt, xs: 3 }}
        >
            <SubTitle title={title} />


            <Button
                onClick={onClick}
                width='200px'
                className='mt-6'
            >
                {buttonText}
            </Button>
        </Box >
    )
}
