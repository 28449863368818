import { Box } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

export default function Logo() {
    return (
        <Box
            component="div"
            width="75px"
        >

            <Link to="/">
                {/* <img src={logo || "/media/logo.png"} alt='zana-logo' /> */}
                <img src="/media/logo.png" alt='zana-logo' />
            </Link>

        </Box>

    )
}
