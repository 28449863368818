import { Box, Typography } from '@mui/material'
import React from 'react'
import PageHeader from '../components/page-header/PageHeader'
import PlaneCardBox from '../components/plane-card-box/PlaneCardBox'
import { ButtonType } from '../components/button/button.props'

export default function Pricing() {


    return (
        <Box
            component="div"
        >
            <PageHeader
                title={"Choose your plan."}
                className='mb-12 text-center'
            />

            <Box
                component="div"
                sx={{
                    display: "flex",
                    gap: "25px",
                    flexWrap: "wrap",
                    flexDirection: {
                        xs: "column", sm: "row"
                    }
                }}
            >
                <PlaneCardBox
                    price={<>
                        <span className='font-bold'>$179 each</span>
                    </>}
                    path='/plans/one-time'
                    title='One at a Time'
                    buttonType={ButtonType.OUTLINE}
                    content={<>
                        Zana Sonic Toothbrush ($220)<br />
                        Practice Imprinting on Brush <br />
                        FREE Replacement Heads for Life ($250)<br />
                        Free Drop Shipping
                    </>}

                />

                <PlaneCardBox
                    price={<>
                        <span className=' font-bold'>$327 / mo,</span> 3 brushes for <span className='font-bold'>$109 each</span>
                    </>}
                    path='/plans/service-&-save'
                    title='Subscribe & Save'
                    buttonType={ButtonType.COLORED}
                    content={<>
                        Privately labeled + Patients name <br />
                        Free Drop Shipping <br />
                        Free Custom Inserts <br />
                        FREE Replacement Heads for Life ($250) <br />
                        No Overhead or Inventory Success Coach
                    </>}
                />

                <PlaneCardBox
                    price={<>
                        <span className='font-bold'>$3096 once,</span> 24 brushes for <span className='font-bold'>$129 each</span>
                    </>}
                    path='/plans/bulk-buy'
                    title='Bulk Buy'
                    buttonType={ButtonType.OUTLINE}
                    content={<>
                        Privately labeled + Patients name  <br />
                        Free Drop Shipping  <br />
                        Free Custom Inserts  <br />
                        FREE Replacement Heads for Life ($250)  <br />
                        No Overhead or Inventory Success Coach
                    </>}
                />
            </Box >
        </Box >
    )
}
