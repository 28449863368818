import React from 'react'
import { SubTitleProps } from './subTitle.props'
import { Typography, Divider, Box } from '@mui/material'

export default function SubTitle({ title, className = "" }: SubTitleProps) {
    return (
        <Box
            component="div"
            className={`${className}`}
        >
            <Typography
                fontSize="14px"
                fontWeight="700"
                lineHeight="1.4"
                py="20px"
                color="rgb(31 47 76)"
            >
                {title}
            </Typography>

            <Divider />
        </Box>
    )

}
