import { Box } from '@mui/material'
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from '../../hooks/store.hook';
import Button from '../button/Button';
import { InputType } from '../input/input.props';
import Input from '../input/Input';
import { useNavigate } from 'react-router-dom';
import RedirectLink from '../redirect-link/RedirectLink';
import { login } from '../../store/thunk-actions/auth-actions';
import SubmitButton from '../submit-button/SubmitButton';

const schema = yup.object({
    email: yup.string().email().required("email is required"),
    password: yup.string().required("password is required"),
}).required();

export default function SigInForm() {

    const navigate = useNavigate()

    const { loading } = useAppSelector(s => s.auth)
    const dispatch = useAppDispatch()

    //form part
    const { control, handleSubmit, setValue, formState: { errors, isDirty, isValid }, reset } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            email: "",
            password: ""
        }
    });

    const onSubmit = (data: any) => {
        dispatch(login({ credentials: data }))
            .unwrap().then(() => {
                reset(s => ({
                    ...s,
                    ...data
                }))
                navigate("/")
            })
    }


    return (
        <Box
            component="div"
            width={{
                md: "340px", xs: "100%"
            }}
            mx="auto"
        >
            <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                mb={2}
                mt={3}
            >

                <Input
                    control={control}
                    name="email"
                    error={errors.email?.message}
                    label="Email *"
                    buttonType='email'
                    type={InputType.WHITE}
                />

                <Input
                    control={control}
                    name="password"
                    error={errors.password?.message}
                    label="Password *"
                    buttonType='password'
                    type={InputType.WHITE}
                />

                <SubmitButton
                    isValid={isValid}
                    isDirty={isDirty}
                    loading={loading}
                    text={'Login'}
                />


            </Box>

            <RedirectLink
                text='Forgot your password?'
                path='/reset-password'
            />

            <Button
                onClick={() => navigate("/signup")}
                className='mt-8'
            >
                Don't have an account
            </Button>

        </Box >

    )
}
