import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Plan as IPlan } from '../interfaces/plan'
import { plans } from '../data/plans'
import { Box, CircularProgress, Typography } from '@mui/material'
import PageHeader from '../components/page-header/PageHeader'
import Button from '../components/button/Button'
import { ButtonType } from '../components/button/button.props'
import { useAppDispatch, useAppSelector } from '../hooks/store.hook'
import CardInfo from '../components/payment-method/card-info/CardInfo'
import { toggleCheckoutPlanModel } from '../store/slices/model.slice'

export default function Plan() {

    const dispatch = useAppDispatch();
    const { id } = useParams()

    const { paymentMethod: { paymentMethods }, plan: { loading } } = useAppSelector(s => s)

    const defaultCard = paymentMethods.find(method => method.isDefault)

    const [plan, setPlan] = useState<IPlan>(null)

    useEffect(() => {
        const plan = plans.find(item => item.id == id);

        setPlan(plan)
    })

    return (
        <Box
            component="div"
            maxWidth="480px"
            mx="auto"
        >
            <PageHeader
                title='Setup your billing.'
                className='text-center mb-12'
            />
            {plan && <>
                <Box
                    component="div"
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mb: 2
                    }}
                >
                    <Typography
                        component="div"
                        color="#1f2f4c"
                        fontSize="24px"
                        lineHeight="1.5"
                        fontWeight="500"
                    >
                        {plan.name}
                    </Typography>
                    <Typography
                        component="div"
                        color="#1f2f4c"
                        fontSize="24px"
                        lineHeight="1.5"
                        fontWeight="500"
                    >
                        {plan.cost}
                    </Typography>
                </Box>

                <Box
                    component="div"
                    mb={4}
                >
                    {plan.details.map((item, index) => <div
                        key={index}
                        className='flex justify-between'
                    >
                        <Typography
                            component="div"
                            color="#1f2f4c"
                            fontSize="16px"
                            lineHeight="1.5"
                        >
                            {item.title}
                        </Typography>
                        <Typography
                            component="div"
                            color="#1f2f4c"
                            fontSize="16px"
                            lineHeight="1.5"
                        >
                            {item.data}
                        </Typography>
                    </div>)}
                </Box>

                <Box
                    component="div"
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: {
                            sm: "center",
                            xs: "flex-start"
                        },
                        mb: 4,
                        flexDirection: {
                            sm: "row", xs: "column"
                        }
                    }}
                >
                    <Typography
                        component="div"
                        color="#1f2f4c"
                        fontSize="24px"
                        lineHeight="1.5"
                        fontWeight="500"
                    >
                        Card Info
                    </Typography>

                    <CardInfo />

                </Box>

                <Typography
                    component="div"
                    color="#1f2f4c"
                    fontSize="14px"
                    lineHeight="1.7"
                    textAlign="center"
                >
                    {plan.note}
                </Typography>


                <Button
                    onClick={() => dispatch(toggleCheckoutPlanModel(plan))}
                    type={!defaultCard ? ButtonType.DISABLED : ButtonType.COLORED}
                    width='340px'
                    className='mx-auto mt-6'
                >
                    Confirm {plan.cost}
                </Button>
            </>}
        </Box>
    )
}
