import React from 'react'
import style from "./Container.module.css"
import { ContainerProps } from './containerProps'
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
import { IconButton } from '@mui/material';
import { useNavigate } from "react-router-dom"
import { useAppSelector } from '../../../hooks/store.hook';
import { mobileBreakPoint } from '../../../utilities/constant';

function Container({ children }: ContainerProps) {

    const { isLoggedIn } = useAppSelector(s => s.user)

    const navigate = useNavigate()
    return (
        <div className={`${style.container} relative`}>

            {isLoggedIn && <IconButton
                onClick={() => navigate(-1)}
                aria-label="Example"
                sx={{
                    position: "absolute",
                    top: { md: "40px", xs: "30px" },
                    left: "32px",
                }}
            >
                <ArrowBackSharpIcon sx={{ fontSize: { md: "40px", xs: "30px" } }} />
            </IconButton>}


            {children}
        </div>
    )
}

export default Container