import React from 'react'
import { InputProps, InputType } from './input.props'
import { Box, TextField } from '@mui/material'
import { useForm, Controller } from "react-hook-form";
import style from "./Input.module.css"

export default function Input({
    control,
    name,
    error,
    label,
    type = InputType.DEFAULT,
    placeholder = "",
    buttonType = "text",
    width = "100%",
    capitalize = false,
}: InputProps) {

    return (
        <Box
            component="div"
            width={width}
            className={`${style.input} ${style[type]}`}
        >
            <label className='block'>
                {label}
            </label>
            <Controller
                name={name}
                control={control}
                render={({ formState, field }) => <TextField
                    error={error !== ""}
                    helperText={error}
                    placeholder={placeholder}
                    sx={{
                        display: "block",
                        mb: 3,
                        py: 0,
                        ".MuiOutlinedInput-input": {
                            textTransform: capitalize ? "capitalize" : "none"
                        }
                    }}
                    variant="outlined"
                    type={buttonType}
                    fullWidth
                    {...field}

                    className={`${error ? style["error-input"] : ""}`}
                />
                }
            />
        </Box>
    )
}
