import { Box, Typography } from '@mui/material';
import { useAppSelector } from '../hooks/store.hook';
import PageHeader from '../components/page-header/PageHeader';
import CardBox from '../components/card-box/CardBox';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import useWindowSize from '../hooks/useWindowSize';

const Home = () => {

    const { width } = useWindowSize()

    const {
        user: { user: { first_name } },
        order: { count, activeCount },
        plan: { plan },
        credits: { count: credits },
        subscription: { subscription }
    } = useAppSelector(s => s)

    const navigate = useNavigate()

    return (
        <Box
            component={"div"}
            width={{
                md: "auto", xs: `calc(${width}px - 107px)`,

            }}
        >
            <PageHeader
                title={`Welcome, ${first_name}`}
                className='mb-6'
            />

            <Box
                component="div"
                sx={{
                    display: "flex",
                    gap: "25px",
                    flexWrap: "wrap",
                    flexDirection: {
                        xs: "column", sm: "row"
                    }
                }}
            >
                <CardBox
                    onClick={() => navigate("/orders")}
                    image='/media/Brush-Package.svg'
                    title='My Orders'
                    buttonText={'Manage Orders'}
                >
                    <div>
                        <Typography
                            component="div"
                            color="#797e96"
                            fontSize="14px"
                            fontWeight="500"
                        >
                            Active Orders
                        </Typography>
                        <Typography
                            component="div"
                            color="#1f2f4c"
                            fontSize="23px"
                            fontWeight="500"
                            lineHeight="1.5"
                            mt="4px"
                        >
                            {activeCount}
                        </Typography>
                    </div>

                    <div className='mt-6'>
                        <Typography
                            component="div"
                            color="#797e96"
                            fontSize="14px"
                            fontWeight="500"
                        >
                            Total Orders
                        </Typography>
                        <Typography
                            component="div"
                            color="#1f2f4c"
                            fontSize="23px"
                            fontWeight="500"
                            lineHeight="1.5"
                            mt="4px"
                        >
                            {count}
                        </Typography>
                    </div>

                </CardBox>

                <CardBox
                    onClick={() => navigate("/billing")}
                    image='/media/Coins.svg'
                    title={`My Plan - ${plan?.name ? plan.name : "----"}`}
                    buttonText={'Manage Plan'}
                >
                    <div>
                        <Typography
                            component="div"
                            color="#797e96"
                            fontSize="14px"
                            fontWeight="500"
                        >
                            Credit Balance
                        </Typography>
                        <Typography
                            component="div"
                            color="#1f2f4c"
                            fontSize="23px"
                            fontWeight="500"
                            lineHeight="1.5"
                            mt="4px"
                        >
                            {credits}
                        </Typography>
                    </div>

                    <div className='mt-6'>
                        <Typography
                            component="div"
                            color="#797e96"
                            fontSize="14px"
                            fontWeight="500"
                        >
                            Next Credits:
                        </Typography>
                        <Typography
                            component="div"
                            color="#1f2f4c"
                            fontSize="21px"
                            fontWeight="500"
                            lineHeight="1.5"
                            mt="4px"
                        >
                            {plan?.isMonthly && subscription?.exp_date ?
                                `${plan.quantity} credits on ${moment(subscription.exp_date).format('M/DD/YY')}`
                                : "----"}
                        </Typography>
                    </div>

                </CardBox>

                <CardBox
                    onClick={() => window.location.href = "mailto:info@myzana.com"}
                    image='/media/Brush-Package.svg'
                    title='My Support'
                    buttonText={'Reach Us'}
                >
                    <div>
                        <Typography
                            component="div"
                            color="#1f2f4c"
                            fontSize="14px"
                            fontWeight="500"
                            lineHeight="1.7"
                        >
                            We’re here to make your practice successful. In our experience, practices see remarkable results when they implement <a className='underline' href='#'>these 5 basic principles.</a>
                        </Typography>
                    </div>
                </CardBox>
            </Box>

        </Box>

    )
}

export default Home