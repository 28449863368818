import { Box } from '@mui/material'
import React from 'react'
import PageHeader from '../components/page-header/PageHeader'
import InviteLoginForm from '../components/invite-login-form/InviteLoginForm'

export default function InviteLogin() {
    return (
        <Box
            component="div"
            maxWidth="600px"
            mx="auto"
            pt="80px"
        >
            <PageHeader
                title="You've been invited to Zana. Enter your email, invite code, and create a password to get started."
                className='text-center mb-14'
            />

            <InviteLoginForm />

        </Box>)
}
