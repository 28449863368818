import { Box, Typography, List } from '@mui/material'
import NaveListItem from '../nav-list/NaveListItem'
import { navListGroupProps } from './navListGroup.props'
import { light } from '@mui/material/styles/createPalette'

function NavListGroup({ list, title, open }: navListGroupProps) {

    return (
        <Box sx={{ py: "16px", px: "24px" }}>
            {open && <Typography
                variant='h6'
                component="div"
                fontSize="12px"
                color="#797e96"
                sx={{
                    pl: "12px",
                    height: "32px",
                    display: "Flex",
                    alignItems: "center",
                    whiteSpace: "pre-wrap"
                }}
                className="capitalize"
            >
                {title}
            </Typography>
            }

            <List sx={{ py: 0, mt: "4px", gap: "4px" }}>
                {list.map(item =>
                    <NaveListItem
                        path={item.path}
                        text={item.text}
                        Icon={item.Icon}
                        key={item.key}
                        open={open}
                        my="8px"
                    />
                )}
            </List>
        </Box>
    )
}

export default NavListGroup