import { Box } from '@mui/material'
import React from 'react'
import PageHeader from '../components/page-header/PageHeader'
import SettingForm from '../components/setting/setting-form/SettingForm'

export default function PracticeName() {
    return (
        <Box
            component="div"
            maxWidth="600px"
            mx="auto"
            pt="80px"
        >

            <PageHeader
                title="What’s the name of your dental practice?"
                className='text-center mb-4'
            />

            <SettingForm
                buttonText={'Next'}
                width='340px'
                className='mx-auto'
            />
        </Box>
    )
}
