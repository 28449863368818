import { createAsyncThunk } from "@reduxjs/toolkit";
import { User } from "../../interfaces/user";
import * as api from "../../utilities/api"
import { Company } from "../../interfaces/company";
import { PaymentMethod } from "../../interfaces/payment-method";



//Add payment method 
export const addPaymentMethod = createAsyncThunk<
    PaymentMethod,
    { token: any },
    {
        rejectValue: unknown
    }
>("paymentMethod/addPaymentMethod", async (payload, thunkApi) => {
    const { rejectWithValue } = thunkApi;
    try {
        const { data } = await api.addCard(payload)
        return data.data;
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

//delete payment 
export const deletePaymentMethod = createAsyncThunk<
    number,
    number,
    {
        rejectValue: unknown
    }
>("paymentMethod/deletePaymentMethod", async (payload, thunkApi) => {
    const { rejectWithValue } = thunkApi;
    try {
        await api.deleteCard(payload)
        return payload;
    } catch (error) {
        return rejectWithValue(error)
    }
})

//set default payment 
export const setDefaultPaymentMethod = createAsyncThunk<
    number,
    number,
    {
        rejectValue: unknown
    }
>("paymentMethod/setDefaultPaymentMethod", async (payload, thunkApi) => {
    const { rejectWithValue } = thunkApi;
    try {
        await api.setDefaultCard(payload)
        return payload;
    } catch (error) {
        return rejectWithValue(error)
    }
})

