import React from 'react'
import { PageHeaderProps } from './pageHeader.props'
import { Box, Typography } from '@mui/material'

function PageHeader({ title, content, className = "" }: PageHeaderProps) {
    return (
        <Box
            component="div"
            className={`${className}`}
        >
            <Typography
                variant='h2'
                component={"div"}
                fontSize={{ md: "34px", xs: "24px" }}
                color="#1f2f4c"
                fontWeight="500"
                lineHeight="1.3"
            >
                {title}
            </Typography>

            {content && <Typography
                component={"p"}
                mt="12px"
                fontSize={{ md: "14px", xs: "12px" }}
                color="#797e96"
                lineHeight="1.7"
            >
                {content}
            </Typography>}

        </Box>
    )
}

export default PageHeader