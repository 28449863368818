import { Typography, Box, CircularProgress } from '@mui/material';
import { useState } from 'react';
import {
    CardElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import { useAppSelector, useAppDispatch } from '../../../hooks/store.hook';
import { toggleCreatePaymentModel } from '../../../store/slices/model.slice';
import { setErrorMessages } from '../../../store/slices/error.slice';
import { setErrorFormat } from '../../../utilities/error-format';
import Button from '../../button/Button';
import { ButtonType } from '../../button/button.props';
import { addPaymentMethod } from '../../../store/thunk-actions/payment-method-action';
import { setSuccessMessage } from '../../../store/slices/success.slice';


const AddPaymentForm = () => {


    const { loading } = useAppSelector(s => s.paymentMethod)
    const [isValid, setIsValid] = useState(false);


    const stripe = useStripe();
    const elements = useElements();


    const dispatch = useAppDispatch()

    const handleCardElementChange = (event: any) => {
        setIsValid(event.complete); // Update isValid based on card validity
    };

    const handleSubmit = async (e: any) => {
        try {
            e.preventDefault();

            if (!stripe || !elements) {
                // Stripe.js has not loaded yet.
                return;
            }

            const { token, error } = await stripe.createToken(elements.getElement(CardElement));

            if (error) throw error



            dispatch(addPaymentMethod({ token })).unwrap().then(() => {
                dispatch(toggleCreatePaymentModel())
                dispatch(setSuccessMessage("Card is added successfully"))
            })



        } catch (error: any) {
            dispatch(setErrorMessages(setErrorFormat(error)))
        }

    };

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
        >
            <Typography
                variant='h2'
                component="div"
                color="#1f2f4c"
                fontSize="24px"
                lineHeight="1.5"
                fontWeight="700"
                mb={4}
                align='center'
            >
                Add New Credited Card
            </Typography>

            <Typography
                variant='h4'
                component="div"
                color="#1f2f4c"
                fontSize="14px"
                lineHeight={1.4}
                mb={2}
                fontWeight="700"
            >
                Card details
            </Typography>

            <CardElement
                className='card'
                options={{
                    disableLink: true,
                    style: {
                        base: {
                            fontSize: '16px',
                            color: '#32325d',
                            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
                            fontSmoothing: 'antialiased',
                            padding: '10px', // Example of adding padding
                            ':disabled': {
                                backgroundColor: '#ececec',
                            },
                        },
                    },


                    hidePostalCode: true
                }}
                onChange={handleCardElementChange}

            />

            <Box
                display="flex"
                gap="16px"
                mt={4}
            >
                <Button
                    width="33%"
                    onClick={() => dispatch(toggleCreatePaymentModel())}
                >
                    Cancel
                </Button>

                <Button
                    type={!isValid || loading ? ButtonType.DISABLED : ButtonType.COLORED}
                    onClick={() => handleSubmit}
                    width='220px'
                >
                    {loading ? <CircularProgress color="inherit" size={30} /> : "Add Card"}

                </Button>

            </Box>

        </Box >

    )
}

export default AddPaymentForm