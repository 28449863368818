import React from 'react'
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { props } from './props';
import { NavLink } from 'react-router-dom';
import "./NaveListItem.css"

function NaveListItem({ path, Icon, text, open, my }: props) {
    return (
        <ListItem
            disablePadding
            sx={{
                display: 'block',
                my: my ? my : 0
                // padding: "4px 24px"
            }}
        >
            <ListItemButton
                sx={{
                    justifyContent: open ? 'initial' : 'center',
                    padding: 0,
                    margin: 0
                }}
            >
                <NavLink to={path}
                    className={({ isActive }) => [
                        isActive ? "active-nav" : "",
                        "nav-link",
                        "flex",
                        "items-center",
                        "w-full",
                        open ? "" : "justify-center"
                    ].join(" ")}>
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? "16px" : 'auto',
                            justifyContent: 'center',
                        }}
                    >
                        <Icon sx={{ hight: 20, width: 20 }} />
                    </ListItemIcon>
                    <ListItemText
                        primary={text}
                        primaryTypographyProps={{
                            fontSize: "14px",
                            fontWeight: "500"
                        }}
                        sx={{ fontSize: "14px", opacity: open ? 1 : 0, my: 0 }}
                    />
                </NavLink >
            </ListItemButton>
        </ListItem >
    )
}

export default NaveListItem