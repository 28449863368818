import { Box } from '@mui/material'
import React from 'react'
import PageHeader from '../components/page-header/PageHeader'
import SignupForm from '../components/signup-form/SignupForm'
import SendRestPasswordLink from '../components/reset-password/SendResetPasswordLink'
import { useSearchParams } from 'react-router-dom'
import RestPasswordForm from '../components/reset-password/RestPassword'

export default function RestPassword() {

    const [searchParams, setSearchParams] = useSearchParams();

    const token = searchParams.get("token")

    return (
        <Box
            component="div"
            maxWidth={{
                md: "600px", xs: "100%"
            }}
            mx="auto"
            pt="80px"
        >


            <PageHeader
                title={
                    token ? "Reset your password here." :
                        "Enter your email and we' ll send you a password reset link."
                }
                className='text-center mb-8'
            />

            {token ?
                <RestPasswordForm token={token} />
                :
                <SendRestPasswordLink />
            }
        </Box>
    )
}
