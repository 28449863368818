import React from 'react'
import Button from '../button/Button'
import { useAppDispatch, useAppSelector } from '../../hooks/store.hook'
import { useNavigate } from 'react-router-dom'
import { logout as logoutAction } from '../../store/slices/user.slice'

export default function LogoutButton() {

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const logout = () => {
        dispatch(logoutAction())
        navigate("/sign-in", { replace: true })
    }

    return (
        <Button
            onClick={logout}
        >
            Logout
        </Button>
    )
}
