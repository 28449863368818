import * as React from 'react';
import Box from '@mui/material/Box';

import CssBaseline from '@mui/material/CssBaseline';
import { Outlet } from 'react-router-dom';
import Navigation from './navigation/Navigation';
import Models from '../../models/Models';
import { useAppSelector } from '../../hooks/store.hook';
import Container from './container/Container';
import { mobileBreakPoint } from '../../utilities/constant';
import useWindowSize from '../../hooks/useWindowSize';

export default function FirstLayout() {

    const { width } = useWindowSize()

    const { model: { isOpen } } = useAppSelector(s => s)

    return (
        <>
            <Models />
            <Box sx={{
                display: 'flex',
                filter: isOpen ? "blur(5.44px)" : "none"
            }}>
                <CssBaseline />

                <Navigation />

                <Box component="main"
                    sx={{
                        flexGrow: 1,
                        [mobileBreakPoint]: {
                            width: `calc(${width}px - 57px)`,
                            // overflow: "hidden"
                        }
                    }}>
                    <Container>
                        <Outlet />
                    </Container>
                </Box>

            </Box >

        </>

    );
}