import { SignupCredentials } from './../interfaces/auth';
import axios from "axios";
import { LoginCredentials } from "../interfaces/auth";
import { User } from '../interfaces/user';
import { Company } from '../interfaces/company';
import { Query } from '../interfaces/query.interface';

const baseURL: string = process.env.REACT_APP_API_URL;

const API = axios.create({ baseURL });

API.interceptors.request.use((req: any) => {

    const token = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")!) : null
    if (token) {
        req.headers.Authorization = `Bearer ${token}`;
    } else {
        req.headers.Authorization = `Bearer `;
    }
    return req;
});


let config = {
    headers: { "Content-Type": "multipart/form-data" }
};

let authConfig = {
    headers: { crossDomain: true, 'Content-Type': 'application/json' },
    withCredentials: true
}
//auth
export const login = (data: LoginCredentials) => API.post("/auth/login", data)

export const signup = (data: SignupCredentials) => API.post("/auth/signup", data)

export const logout = () => API.post("/logout", {}, authConfig)


//password
export const sendResetPasswordEmail = (data: { email: string }) => API.post("/passwords/send-link", data)

export const passwordReset = (data: {
    token: string,
    password: string,
    confirmPassword: string
}) => API.post("/passwords/rest-password", data)

//user
export const getUser = () => API.get("/users")
export const updateUser = (data: Partial<User>) => API.put("/users", data)

//companies
export const updateCompanyName = (data: Partial<Company>) => API.post("/companies", data)
export const uploadCompanyLogo = (data: any) => API.post("/companies/logo", data, config)

//orders
export const getUserOrders = ({ limit = 1, page = 1 }: Partial<Query>) => API.get(`/orders?limit=${limit}&page=${page}`)
export const getUserOrder = (id: number) => API.get(`/orders/${id}`)
export const createOrder = (data: {
    order: {
        imprint_name: string,
        shipping_address: string
    },
    address: {
        address_Line_2: string,
        zip_code: string,
        city: string,
        state: string
    }

}) => API.post("/orders", data)

//checkout
export const planCheckOut = (data: { planId: number }) => API.post("/checkout/plans", data)
export const orderCheckOut = (data: {
    order: {
        imprint_name: string,
        shipping_address: string
    },
    address: {
        address_Line_2: string,
        zip_code: string,
        city: string,
        state: string
    }

}) => API.post("/checkout/orders", data)

//payment methods
export const addCard = (data: any) => API.post("/cards", data)
export const deleteCard = (id: number) => API.delete(`/cards/${id}`)
export const setDefaultCard = (id: number) => API.patch(`/cards/${id}`)

//payments
export const getPayment = (chargeId: string) => API.get(`/payments/${chargeId}`)

export const getPlan = (id: number) => API.get(`/plans/${id}`)


// export const deleteUserImage = () => API.delete("/users/image")


