import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import PaymentInfo from '../components/payment-info/PaymentInfo';
import Loading from '../components/loading/Loading';
import { useAppDispatch } from '../hooks/store.hook';
import { setErrorMessages } from '../store/slices/error.slice';
import { setErrorFormat } from '../utilities/error-format';
import { getPayment } from '../utilities/api';
import { updateUserPlan } from '../store/slices/plan.slice';
import { addCredit } from '../store/slices/credits.slice';
import { updateUserSubscription } from '../store/slices/subscription.slice';
import { addOrder } from '../store/slices/order.slice';



export default function Payments() {

    const dispatch = useAppDispatch();

    const [loading, setLoading] = useState(true)
    const [payment, setPayment] = useState(null)
    const [searchParams, setSearchParams] = useSearchParams();

    const chargeId = searchParams.get("payment_intent")

    const fetchPayment = async () => {

        try {
            let payment;
            const { data } = await getPayment(chargeId);
            payment = data.data;

            if (payment.status === "pending") {
                setTimeout(fetchPayment, 5000);
            } else {

                console.log("payment = ", payment)
                if (payment.status === "success") {
                    if (payment.type === "plan") {
                        dispatch(updateUserPlan(payment));
                        dispatch(addCredit(payment.plan.quantity))

                        if (payment.plan.isMonthly)
                            dispatch(updateUserSubscription())
                    } else if (payment.type === "credit") {
                        dispatch(addOrder(payment.order))
                    }
                }
                setLoading(false)
                setPayment(payment);
            }
        } catch (error) {
            throw error
        }
    }


    async function getPaymentInfo() {
        try {

            await fetchPayment()

        } catch (error) {
            setLoading(false)
            dispatch(setErrorMessages(setErrorFormat(error)))
        }
    }

    useEffect(() => {
        getPaymentInfo()
    }, [chargeId]);

    console.log("loading", loading)
    return (
        <>
            {loading ? <Loading /> : <PaymentInfo payment={payment} />}

        </>
    )
}
