import { createSlice } from "@reduxjs/toolkit";
import { Company } from "../../interfaces/company";
import { updateCompanyName, uploadCompanyLogo } from "../thunk-actions/company-action";
import { getUser } from "../thunk-actions/user-action";
import { login } from "../thunk-actions/auth-actions";
import { logout } from "./user.slice";


const initialState: { count: number } = {
    count: 0
}

const slice = createSlice({
    name: "credits",
    initialState,
    reducers: {
        addCredit(state, { payload }) {
            state.count = +state.count + payload
        },
        subtractionCredit(state) {
            state.count -= 1
        },
    },

    extraReducers: (builder) => {


        //update company with get user
        builder.addCase(getUser.fulfilled, (state, action) => {
            state.count = action.payload.credits || 0
        })

        //update company with login
        builder.addCase(login.fulfilled, (state, action) => {
            state.count = action.payload.user.credits || 0


        })

        //update company with logout
        builder.addCase(logout, (state) => {
            state.count = 0
        })


    }
})

const {
    addCredit,
    subtractionCredit
} = slice.actions;

export {
    addCredit,
    subtractionCredit
}

export default slice.reducer;