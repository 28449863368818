import React from 'react'
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NaveListItem from '../../../components/nav-list/NaveListItem';
import List from '@mui/material/List';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import { Box } from '@mui/material';
import { useAppSelector } from '../../../hooks/store.hook';
import NavListGroup from '../../../components/nav-list-group/NavListGroup';
import { bottomNav, middleNav, topNav } from '../../../config/navigation';
import LogoutButton from '../../../components/logout-button/LogoutButton';
import Logo from '../../../components/logo/Logo';
import useWindowSize from '../../../hooks/useWindowSize';

const drawerWidth = 256;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

function Navigation() {

    const { width } = useWindowSize()


    const {
        user: { user: { first_name, last_name, email }, isLoggedIn },
        company: { company: { logo, name } }
    } = useAppSelector(s => s)

    const [open, setOpen] = React.useState(width > 767);

    const handleDrawerOpen = () => {
        setOpen(s => !s);
    };


    return (
        <Drawer
            variant="permanent"
            open={open}
        >
            <DrawerHeader
                sx={{
                    justifyContent: open ? "space-between" : "center",
                    p: "34px 32px",
                    minHeight: "0 !important"
                }}
            >
                {open && <Logo />}
                <IconButton
                    onClick={handleDrawerOpen}
                    sx={{
                        padding: 0
                    }}
                >
                    {open ? <ChevronLeftIcon /> : <MenuIcon />}
                </IconButton>
            </DrawerHeader>
            <Divider />
            <Box sx={{ py: "16px", px: "24px" }}>
                <List sx={{ py: 0 }}>
                    {topNav.map(item => <NaveListItem
                        key={item.key}
                        path={item.path}
                        text={item.text}
                        Icon={item.Icon}
                        open={open}
                    />
                    )}

                </List>
            </Box>
            <Divider />
            <NavListGroup title={`My Practice - ${name}`} list={middleNav} open={open} />
            <Divider />
            <NavListGroup
                title={`Myself - ${first_name} ${last_name}`}
                list={bottomNav}
                open={open}
            />
            {open && <Box sx={{ px: "24px", mb: 3 }}>
                <LogoutButton />
            </Box>}
        </Drawer >
    )
}

export default Navigation